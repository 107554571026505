import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Context } from "../../contexts/Store";

export default function BodyType() {
	const { state } = useContext(Context);

	const renderImage = (bodyType) => {
		return <img src={bodyType.image} alt="" />;
	};

	return (
		<OptionsContainer className="pdfSection">
			<div className="imageSection">
				<div className="image-box">
					<img
						src={
							require("../../assets/images/body-parts/Frame.svg")
								.default
						}
						alt=""
					/>
				</div>
				{state.bodyParts.map((bodyType) => (
					<div
						key={bodyType.type}
						className={`image-box related-item${
							bodyType.is_selected ? " show" : " hide"
						}`}
					>
						{renderImage(bodyType)}
					</div>
				))}
			</div>
		</OptionsContainer>
	);
}

const OptionsContainer = styled.div`
	&.pdfSection {
		display: flex;
		width: 100%;
		justify-content: center;
		margin-top: 15px;
		position: relative;
	}
	&.pdfSection .imageSection {
	}
	&.pdfSection h6:hover {
		cursor: pointer;
	}
	&.pdfSection h6 {
		font-size: 14px;
		padding-bottom: 3px;
		color: red;
		border-bottom: 1px solid red;
		margin-right: 25px;
	}
	&.pdfSection .image-box {
		width: 250px;
		cursor: pointer;
		@media (max-width: 460px) {
			width: 180px;
		}
	}
	&.pdfSection .image-box img {
		display: block;
		width: 100%;
		height: 100%;
	}
	&.pdfSection .image-box.related-item {
		position: absolute;
		top: 0;
		background-attachment: fixed;
		// display: none;
		opacity: 0;
	}
	&.pdfSection .image-box.related-item.hide {
		animation: animate-out 0.3s ease;
		// display: block;
		opacity: 0;
	}
	&.pdfSection .image-box.related-item.show {
		animation: animate-in 0.3s ease;
		// display: block;
		opacity: 1;
	}
	@keyframes animate-in {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes animate-out {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
`;
