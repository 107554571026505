import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Button from "../includes/Button";
import { useNavigate } from "react-router-dom";
import { THEME_COLORS } from "../../configData";

export default function Acknowledgement() {
	const navigate = useNavigate();

	const feedBackNext = () => {
		navigate("/");
	};

	const newFeedBack = () => {
		navigate("/feedback/verify/");
	};

	const [matches, setMatches] = useState(
		window.matchMedia("(min-width: 760px)").matches
	);

	useEffect(() => {
		window
			.matchMedia("(min-width: 760px)")
			.addEventListener("change", (e) => setMatches(e.matches));
	}, []);

	const onBackButtonEvent = (e) => {
		e.preventDefault();
		navigate("/");
	};

	useEffect(() => {
		window.history.pushState(null, null, window.location.pathname);
		window.addEventListener("popstate", onBackButtonEvent);
		return () => {
			window.removeEventListener("popstate", onBackButtonEvent);
		};
	}, []);

	return (
		<Container>
			<LogoContainer>
				<Logo
					src={
						require("../../assets/icons/aira-logo-vertical.svg")
							.default
					}
				/>
			</LogoContainer>
			<TextContainer>
				<Text>
					Thank you
					<br />
					Visit Again
				</Text>
			</TextContainer>
			<BadgeImage
				src={require("../../assets/icons/round-badge.svg").default}
				alt="icon"
			/>
			<BottomContainer>
				<Button
					color={THEME_COLORS.secondary_2}
					border={THEME_COLORS.secondary_5}
					// background={THEME_COLORS.background_1}
					text="Back to home"
					arrow=""
					width={!matches ? "90%" : "24%"}
					onClick={feedBackNext}
					margin={!matches ? "0px 0px 15px 0px" : ""}
				/>
				<Button
					color={THEME_COLORS.secondary_2}
					border={THEME_COLORS.secondary_5}
					// background={THEME_COLORS.background_1}
					text="New Feedback"
					arrow=""
					width={!matches ? "90%" : "24%"}
					onClick={newFeedBack}
					margin={!matches ? "0px 0px 15px 0px" : ""}
				/>
			</BottomContainer>
		</Container>
	);
}
const Container = styled.div`
	padding-top: 50px;
	background: ${THEME_COLORS.background_1};
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;
const LogoContainer = styled.div`
	width: 140px;
	margin: 0 auto;
`;
const Logo = styled.img`
	display: block;
	width: 100%;
`;
const TextContainer = styled.div`
	width: 40%;
	margin: 0 auto;
	z-index: 99;
	position: relative;
`;
const Text = styled.h2`
	font-style: normal;
	font-weight: 400;
	font-size: 94px;
	color: #000000;
	text-align: center;
	@media (max-width: 1280px) {
		font-size: 50px;
	}
	@media (max-width: 768px) {
		font-size: 42px;
	}
	@media (max-width: 460px) {
		font-size: 32px;
	}
`;
const BottomContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 50px 60px;
	/* @media (max-width: 460px) {
		flex-direction: column;
	} */
`;
const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

const BadgeImage = styled.img`
	animation: ${rotate} 10s linear infinite;
	position: absolute;
	left: 0;
	right: -32%;
	margin-left: auto;
	margin-right: auto;
	top: 34%;
	width: 150px;
	z-index: 1;
	@media (max-width: 768px) {
		display: none;
	}
`;
