import React, { useState, useContext } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../configData";
import { Context } from "../../contexts/Store";

export default function CheckBoxCustom({ item, onClick, grid_column, id }) {
	const [isSelected, setSelected] = useState(false);
	const { state, dispatch } = useContext(Context);

	return (
		<Container
			selected={
				state.profile.body_conditions.find((element) => element === id)
					? "selected"
					: ""
			}
			onClick={() => {
				onClick(item);
				// setSelected((prev) => !prev);
			}}
			grid_column={grid_column}
		>
			<CheckBox
				selected={
					state.profile.body_conditions.find(
						(element) => element === id
					)
						? "selected"
						: ""
				}
			>
				{state.profile.body_conditions.find(
					(element) => element === id
				) && (
					<IconContainerChecked>
						<Checked
							src={require("../../assets/icons/tick.svg").default}
						/>
					</IconContainerChecked>
				)}
			</CheckBox>
			{item}
		</Container>
	);
}
const Container = styled.div`
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	font-size: 22px;
	line-height: 28px;
	letter-spacing: 0.025em;
	grid-column: ${(props) => props.grid_column};
	color: ${({ selected }) =>
		selected === "selected" ? `${THEME_COLORS.primary_1}` : "#6a6a6a"};
	@media (max-width: 980px) {
		font-size: 22px;
	}
	@media (max-width: 640px) {
		margin-bottom: 10px;
		font-size: 20px;
	}
	@media (max-width: 460px) {
		font-size: 18px;
		margin-bottom: 10px;
		/* flex-direction: row; */
	}
`;
const IconContainerChecked = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const CheckBox = styled.div`
	display: block;
	height: 25px;
	width: 25px;
	border: 1px solid #b0b0b0;
	border-color: ${({ selected }) =>
		selected === "selected" ? `${THEME_COLORS.primary_1}` : "#b0b0b0"};
	cursor: pointer;
	margin-right: 15px;
	@media (max-width: 768px) {
		height: 28px;
		width: 28px;
	}
	@media (max-width: 640px) {
		height: 25px;
		width: 25px;
	}
	@media (max-width: 440px) {
		height: 20px;
		width: 20px;
	}
`;
const Checked = styled.img`
	display: block;
	width: 20px;
	height: 30px;
	@media (max-width: 640px) {
		height: 25px;
		width: 15px;
	}
	@media (max-width: 440px) {
		height: 20px;
	}
`;
