import React, { useContext, useState } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import Button from "../../includes/Button";
import { THEME_COLORS } from "./../../../configData";
import { useNavigate } from "react-router-dom";
import animationData from "../../../assets/lotties/success2.json";
import { baseConfig } from "../../../axiosConfig";

// MI UI imports
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { Context } from "../../../contexts/Store";

export default function FeedbackForm() {
	// Form state
	const [mail, setMail] = useState("");
	const [name, setName] = useState("");
	const [error, setError] = useState("");
	const [isVerified, setVerified] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const { dispatch } = useContext(Context);

	// Handle lottie event completion
	const eventListeners = [
		{
			eventName: "complete",
			callback: () => feedBackNext(),
		},
	];

	// Handle navigation
	const navigate = useNavigate();
	const feedBackNext = () => {
		navigate("/feedback/reception/");
		setLoading(false);
	};

	// Handle email verification
	const handleEmail = () => {
		setLoading(true);
		baseConfig
			.post("api/v1/users/verify-client/", {
				email: mail,
			})
			.then((response) => {
				let { StatusCode, message, data } = response.data;
				if (StatusCode === 6000) {
					setVerified(true);
					setName(data.name);
					const user_details = {
						user_id: data.id,
					};
					dispatch({
						type: "UPDATE_USER_DETAILS",
						user_details,
					});
					setLoading(false);
				} else {
					setError(message);
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	const handleClose = () => setError("");

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleEmail();
		}
	};

	return (
		<Container>
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar
					open={error ? true : false}
					autoHideDuration={3000}
					onClose={handleClose}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<Alert
						onClose={handleClose}
						severity="error"
						sx={{ width: "100%" }}
					>
						{error}
					</Alert>
				</Snackbar>
			</Stack>
			{isVerified ? (
				<LottieContainer>
					<Lottie
						options={{
							loop: false,
							autoplay: true,
							animationData: animationData,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice",
							},
						}}
						height={100}
						width={100}
						eventListeners={eventListeners}
					/>
					<Text>Verified as</Text>
					<UserName>{name}</UserName>
				</LottieContainer>
			) : (
				<Box
					component="form"
					sx={{
						"& > :not(style)": { mb: 4, width: "100%" },
					}}
					noValidate
					autoComplete="off"
				>
					<TextField
						type="email"
						color="success"
						id="standard-basic"
						label="Email*"
						variant="standard"
						InputProps={{
							style: {
								fontSize: 20,
								fontFamily: "Agatho-Bold",
								lineHeight: 1.5,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 20,
								color: "#6A6A6A",
								fontFamily: "DMMono-Regular",
							},
						}}
						onChange={(e) => {
							setMail(e.target.value);
						}}
						value={mail}
						onKeyDown={handleKeyDown}
					/>
				</Box>
			)}
			<Button
				color={THEME_COLORS.secondary_2}
				border={THEME_COLORS.secondary_5}
				// background={THEME_COLORS.secondary_2}
				text="Rate our Service"
				// arrow="left"
				width="100%"
				onClick={() => {
					handleEmail();
				}}
				isLoading={isLoading}
			/>
		</Container>
	);
}

const Container = styled.div`
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const LottieContainer = styled.div``;
const Text = styled.h4`
	font-family: "DMMono-Regular";
	font-size: 20px;
	line-height: 26px;
	color: #6a6a6a;
	margin: 30px 0 10px 0;
	text-align: center;
`;
const UserName = styled.h3`
	font-weight: 700;
	font-size: 30px;
	line-height: 32px;
	color: #6e421b;
	text-align: center;
`;
