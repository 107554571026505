import React, { useState, useContext } from "react";
import styled from "styled-components";
import Button from "../../includes/Button";
import { THEME_COLORS } from "./../../../configData";
import { useNavigate, useOutletContext } from "react-router-dom";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";

// MI UI imports
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

export default function FeedbackDescription() {
	// Router constants
	const navigate = useNavigate();

	// Outlet props
	const [{ resultObject }] = useOutletContext();

	// States
	const { state } = useContext(Context);
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [text, setText] = useState("");

	const feedbackSubmit = () => {
		navigate("/acknowledgement/");
	};

	const previousButton = () => {
		navigate(-1);
	};

	// Submit feedback details
	const handleSubmit = () => {
		setLoading(true);
		baseConfig
			.post("api/v1/users/feedback/", {
				customer: state.user_details.user_id,
				feedback: text,
				...resultObject,
			})
			.then((response) => {
				let { StatusCode, message } = response.data;
				if (StatusCode === 6000) {
					feedbackSubmit();
					setLoading(false);
				} else {
					setLoading(false);
					setError(message);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	const handleClose = () => setError("");

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleSubmit();
		}
	};

	return (
		<Container>
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar
					open={error ? true : false}
					autoHideDuration={3000}
					onClose={handleClose}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<Alert
						onClose={handleClose}
						severity="error"
						sx={{ width: "100%" }}
					>
						{error}
					</Alert>
				</Snackbar>
			</Stack>
			<Box
				component="form"
				sx={{
					"& > :not(style)": { mb: 4, width: "100%" },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					type="text"
					color="success"
					id="standard-basic"
					label="Write a short feedback"
					variant="standard"
					InputProps={{
						style: {
							fontSize: 20,
							fontFamily: "Agatho-Bold",
							lineHeight: 1.5,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 20,
							color: "#6A6A6A",
							fontFamily: "DMMono-Regular",
						},
					}}
					onChange={(e) => {
						setText(e.target.value);
					}}
					value={text}
					onKeyDown={handleKeyDown}
				/>
			</Box>
			<ButtonDiv>
				<Button
					color={THEME_COLORS.secondary_2}
					border={THEME_COLORS.secondary_5}
					// background={THEME_COLORS.background_1}
					text="Previous"
					arrow="left"
					width="48%"
					onClick={previousButton}
				/>
				<Button
					color={THEME_COLORS.secondary_2}
					border={THEME_COLORS.secondary_5}
					// background={THEME_COLORS.secondary_2}
					text="Submit"
					// arrow="left"
					width="48%"
					onClick={handleSubmit}
					isLoading={isLoading}
				/>
			</ButtonDiv>
		</Container>
	);
}

const Container = styled.div`
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	height: 100%;
	user-select: none;
`;
const ButtonDiv = styled.div`
	justify-content: space-between;
	display: flex;
	/* flex-direction: column; */
	/* height: 100%; */
`;
