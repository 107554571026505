import React, { useState } from "react";
import "./assets/css/style.css";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./components/screens/LandingPage";
import MainPage from "./components/screens/form/MainPage";
import Form1 from "./components/screens/form/Form1";
import Form2 from "./components/screens/form/Form2";
import Form3 from "./components/screens/form/Form3";
import Form4 from "./components/screens/form/Form4";
import Form5 from "./components/screens/form/Form5";
import Form6 from "./components/screens/form/Form6";
import FeedbackForm from "./components/screens/feedback-form/FeedbackForm";
import ReceptionForm from "./components/screens/feedback-form/ReceptionForm";
import Question from "./components/screens/questions/Question";
import StartBox from "./components/includes/home/StartBox";
import PrakrithiStart from "./components/includes/home/PrakrithiStart";
import Result from "./components/screens/questions/Result";
import FeedbackDescription from "./components/screens/feedback-form/FeedbackDescription";
import Acknowledgement from "./components/includes/Acknowledgement";
import Error404 from "./components/includes/error/Error404";

export default function App() {
	const [pages] = useState({
		form1: {
			title: "Enter Your Details",
			image: "first",
		},
		form2: {
			title: "Enter Your Details",
			image: "first",
		},
		form3: {
			title: "Enter Your Details",
			image: "first",
		},
		form4: {
			title: "Pressure Preference",
			image: "second",
		},
		form5: {
			title: "Choose Body Parts",
			image: "third",
		},
	});

	return (
		<Routes>
			<Route path="/" element={<LandingPage />}>
				<Route path="/" element={<StartBox />} />
				<Route path="/start/" element={<PrakrithiStart />} />
			</Route>
			<Route
				path="/prakriti/"
				element={
					<MainPage
						title={"Choose Body Parts"}
						image_type={"third"}
					/>
				}
			>
				<Route path="/prakriti/register/" element={<Form1 />} />
				<Route path="/prakriti/personal/:id/" element={<Form2 />} />
				<Route path="/prakriti/know-us/:id/" element={<Form3 />} />
				<Route path="/prakriti/preference/:id/" element={<Form4 />} />
				<Route path="/prakriti/body-parts/:id/" element={<Form5 />} />
				<Route path="/prakriti/conditions/:id/" element={<Form6 />} />
			</Route>
			<Route
				path="/questions"
				element={<MainPage title={"Know your Prakriti"} />}
			>
				<Route path="/questions/:id/" element={<Question />} />
			</Route>
			<Route path="/result/:id/" element={<Result />} />
			<Route
				path="/feedback/"
				element={
					<MainPage
						title={"Enter your Feedback"}
						image_type={"third"}
						router="feedback" // For feedback form
					/>
				}
			>
				<Route path="/feedback/verify/" element={<FeedbackForm />} />
				<Route
					path="/feedback/reception/"
					element={<ReceptionForm />}
				/>
				<Route
					path="/feedback/therapist/"
					element={<ReceptionForm />}
				/>
				<Route
					path="/feedback/amenities/"
					element={<ReceptionForm />}
				/>
				<Route
					path="/feedback/submit/"
					element={<FeedbackDescription />}
				/>
			</Route>
			<Route path="/acknowledgement/" element={<Acknowledgement />} />
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
}
