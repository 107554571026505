import React from "react";
import styled, { keyframes } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { THEME_COLORS } from "../../../configData";

export default function Error404() {
	const navigate = useNavigate();

	const feedBackNext = () => {
		navigate("/");
	};

	return (
		<Container>
			<LogoContainer>
				<Logo
					src={
						require("../../../assets/icons/aira-logo-vertical.svg")
							.default
					}
				/>
			</LogoContainer>
			<TextContainer>
				<Text>
					4
					<BadgeImage
						src={
							require("../../../assets/icons/round-badge.svg")
								.default
						}
						alt="icon"
					/>
					4
				</Text>
			</TextContainer>
			<BottomContainer>
				<ErrorText>Error, Something went wrong</ErrorText>
				<BackButtonDiv to={"/"}>
					<BackButton>Back to home</BackButton>
				</BackButtonDiv>
			</BottomContainer>
		</Container>
	);
}
const Container = styled.div`
	padding-top: 50px;
	background: ${THEME_COLORS.background_1};
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;
const LogoContainer = styled.div`
	width: 140px;
	margin: 0 auto;
`;
const Logo = styled.img`
	display: block;
	width: 100%;
`;
const TextContainer = styled.div`
	width: 40%;
	margin: 0 auto;
	z-index: 99;
`;
const Text = styled.h2`
	font-size: 300px;
	color: #000000;
`;
const BackButtonDiv = styled(Link)`
	cursor: pointer;
`;
const BackButton = styled.h4`
	color: ${THEME_COLORS.secondary_1};
	font-family: "Agatho-Medium";
	font-size: 28px;
	text-decoration: underline;
`;
const BottomContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 100px;
`;
const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;
const ErrorText = styled.h5`
	font-size: 20px;
	line-height: 26px;
	font-family: "DMMono-Regular";
	margin-bottom: 30px;
	color: ${THEME_COLORS.text_1};
`;

const BadgeImage = styled.img`
	animation: ${rotate} 10s linear infinite;
	z-index: 1;
`;
