import React, { useEffect, useState } from "react";
import { THEME_COLORS } from "./../../configData";
import styled, { keyframes } from "styled-components";
import { Outlet, Link } from "react-router-dom";

function LandingPage() {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	};
	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	return (
		<MainContainer background={THEME_COLORS.background_1}>
			<LineTop />
			<LineBottom />
			<LongStar
				src={require("./../../assets/icons/long-star.svg").default}
				alt="icon"
			/>
			<Container height={height}>
				<BodyContainer>
					<LogoContainer>
						<Link to={"/"}>
							<Logo
								src={
									require("./../../assets/icons/aira-logo.svg")
										.default
								}
								alt="logo"
							/>
						</Link>
					</LogoContainer>
					<BodyDiv>
						<LeftDiv>
							<LeftBottom>
								<HeadingDiv>
									<Heading>
										Find your Way of Relaxation
									</Heading>
									<BadgeImage
										src={
											require("./../../assets/icons/wellness-badge-1.svg")
												.default
										}
										alt="icon"
									/>
								</HeadingDiv>
								<Outlet />
							</LeftBottom>
						</LeftDiv>
						<RightDiv>
							<ImagesDiv>
								<LandingImage
									src={require("./../../assets/images/landing-bg.png")}
									alt="image"
								/>
							</ImagesDiv>
							<FlowerIcon
								src={
									require("./../../assets/icons/flower.svg")
										.default
								}
								alt="logo"
							/>
						</RightDiv>
					</BodyDiv>
				</BodyContainer>
			</Container>
		</MainContainer>
	);
}

export default LandingPage;

const MainContainer = styled.div`
	// min-height: 100vh;
	height: 100%;
	background-color: ${THEME_COLORS.background_1};
	/* padding: 80px; */
	/* height: 100vh; */
	@media (max-width: 460px) {
		/* padding: 40px; */
	}
`;

const LineTop = styled.hr`
	background: ${THEME_COLORS.secondary_2};
	width: 100%;
	height: 1px;
	position: absolute;
	top: 46px;
	@media (max-width: 460px) {
		display: none;
	}
`;
const LineBottom = styled.hr`
	background: ${THEME_COLORS.secondary_2};
	width: 100%;
	height: 1px;
	position: absolute;
	bottom: 46px;
	@media (max-width: 460px) {
		display: none;
	}
`;
const LongStar = styled.img`
	position: absolute;
	bottom: -3px;
	left: 46px;
	@media (max-width: 460px) {
		display: none;
	}
`;
const Container = styled.div`
	position: relative;
	padding: 60px;
	height: ${(props) => (props.height ? `${props.height}px` : null)};
	@media (max-width: 1366px) {
		display: flex;
		align-items: center;
	}
	@media (max-width: 460px) {
		padding: 40px;
	}
	@media (max-width: 370px) {
		padding: 25px;
		height: unset;
	}
`;

const BodyContainer = styled.div``;
const BodyDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 768px) {
		flex-direction: column-reverse;
	}
`;
const LeftDiv = styled.div`
	width: 48%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media (max-width: 768px) {
		width: 100%;
	}
`;
const LeftBottom = styled.div`
	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`;
const HeadingDiv = styled.div`
	position: relative;
	margin-bottom: 32px;
	width: 600px;
	@media (max-width: 1024px) {
		width: 500px;
		height: auto;
	}
	@media (max-width: 980px) {
		width: 400px;
		height: auto;
	}
	@media (max-width: 768px) {
		width: 100%;
		height: auto;
		text-align: center;
	}
	@media (max-width: 460px) {
		width: 100%;
		margin-bottom: 20px;
		width: calc(100% - 40px);
	}
	@media (max-width: 320px) {
		margin-bottom: 10px;
	}
`;
const BadgeImage = styled.img`
	position: absolute;
	top: -10%;
	right: 13%;
	height: 45%;
	width: 20%;
	@media (max-width: 980px) {
		right: 0%;
		top: -15%;
	}
	@media (max-width: 460px) {
		right: -3%;
		top: -15%;
		width: 19%;
	}
`;
const Heading = styled.h1`
	font-family: "Agatho-Regular";
	font-size: 70px;
	color: #000;
	@media (max-width: 1024px) {
		font-size: 64px;
	}
	@media (max-width: 768px) {
		font-size: 35px;
	}
	@media (max-width: 460px) {
		text-align: center;
		font-size: 25px;
	}
`;
const LogoContainer = styled.div`
	margin-bottom: 30px;
	@media (max-width: 768px) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@media (max-width: 460px) {
		margin-bottom: 15px;
	}
	@media (max-width: 320px) {
		margin-bottom: 5px;
	}
`;
const Logo = styled.img`
	width: 200px;
	@media (max-width: 460px) {
		width: 150px;
	}
	@media (max-width: 360px) {
		width: 120px;
	}
`;
// const Link = styled.a`
// 	display: block;
// `;
const RightDiv = styled.div`
	position: relative;
	width: 48%;
	@media (max-width: 768px) {
		width: 60%;
	}
	@media (max-width: 460px) {
		width: 70%;
	}
	@media (max-width: 320px) {
		width: 58%;
	}
`;
const ImagesDiv = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
`;
const LandingImage = styled.img`
	display: inline-block;
	height: 100%;
	width: 70%;
	@media (max-width: 980px) {
		width: 100%;
	}
`;

const rotate = keyframes`
  from {
    transform: rotate(360deg);

  }
  to {
    transform: rotate(0deg);

  }
`;
const FlowerIcon = styled.img`
	position: absolute;
	bottom: 0;
	right: 8%;
	animation: ${rotate} 10s linear infinite;
	height: 20%;
	width: 20%;
	@media (max-width: 980px) {
		height: 25%;
	}
`;
