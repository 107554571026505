import React, {
	useState,
	useRef,
	useEffect,
	useImperativeHandle,
	forwardRef,
} from "react";

// MI UI imports
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

import styled from "styled-components";
import { useNavigate, useOutletContext } from "react-router-dom";

import { baseConfig } from "./../../../axiosConfig";

const Form1 = (props, ref) => {
	// Router constants
	const navigate = useNavigate();
	const [{ outletRef, setLoading }] = useOutletContext();

	// Form state
	const [dob, setDob] = useState("");
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [mail, setMail] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");

	// Date picker
	const [isDate, setDate] = useState("");

	// Handle date change
	const handleDateChange = (date) => {
		setDob(date);
	};

	//ref
	const nameRef = useRef(null);
	const mailRef = useRef(null);
	const phoneRef = useRef(null);
	const dateRef = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			nameRef.current.focus();
		}, 200);
	}, []);

	useEffect(() => {
		dateRef.current?.focus();
	}, [dateRef.current]);

	// Handle form submit
	const registerAccount = () => {
		setLoading(true);
		let formData = new FormData();
		formData.append("name", name);
		formData.append("phone", phone);
		formData.append("email", mail);
		formData.append("age", dob);
		baseConfig
			.post(`users/register/`, formData)
			.then((response) => {
				const { StatusCode, data, message } = response.data;
				if (StatusCode === 6000) {
					// navigate(
					// 	`/prakriti/personal/${data.id}/?c=${data.client_id}/`
					// );
					navigate({
						pathname: `/prakriti/personal/${data.id}/`,
						search: `?c=${data.client_id}`,
					});
				} else {
					setError(message);
					setLoading(false);
				}
				setLoading(false);
			})
			.catch((error) => {
				setError("An error occurred. Please try again later.");
				setLoading(false);
			});
	};

	useImperativeHandle(outletRef, () => ({
		registerAccount,
	}));

	const handleClose = () => setError("");

	const styles = (theme) => ({
		multilineColor: {
			color: "red",
		},
	});

	return (
		<div>
			<HeadingDiv>
				<Heading>Enter Your Details</Heading>
			</HeadingDiv>
			<Box
				className="boxMenu"
				component="form"
				sx={{
					"& > :not(style)": { mb: 4, width: "100%" },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					inputRef={nameRef}
					style={{
						fontFamily: "Agatho-Bold",
					}}
					color="success"
					id="standard-basic"
					label="Enter Name*"
					variant="standard"
					InputProps={{
						style: {
							fontSize: 20,
							fontFamily: "Agatho-Bold",
							lineHeight: 1.5,
							opacity: 1,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 20,
							color: "#6A6A6A",
							fontFamily: "DMMono-Regular",
						},
					}}
					onChange={(e) => {
						setName(e.target.value);
					}}
					value={name}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
							phoneRef.current.focus();
						}
					}}
				/>
			</Box>
			<Box
				component="form"
				sx={{
					"& > :not(style)": { mb: 4, width: "100%" },
				}}
				noValidate
				autoComplete="off"
				// sx={{ width: 500 }}
			>
				<TextField
					inputRef={phoneRef}
					color="success"
					id="standard-basic"
					label="Phone Number*"
					variant="standard"
					type="number"
					InputProps={{
						style: {
							fontSize: 20,
							fontFamily: "Agatho-Bold",
							lineHeight: 1.5,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 20,
							color: "#6A6A6A",
							fontFamily: "DMMono-Regular",
						},
					}}
					onChange={(e) => {
						setPhone(e.target.value);
					}}
					value={phone}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
							mailRef.current.focus();
						}
					}}
					onWheel={(e) => e.target.blur()}
				/>
			</Box>
			<Box
				component="form"
				sx={{
					"& > :not(style)": { mb: 4, width: "100%" },
				}}
				noValidate
				autoComplete="off"
				// sx={{ width: 500 }}
			>
				<TextField
					inputRef={mailRef}
					type="email"
					color="success"
					id="standard-basic"
					label="Email*"
					variant="standard"
					InputProps={{
						style: {
							fontSize: 20,
							fontFamily: "Agatho-Bold",
							lineHeight: 1.5,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 20,
							color: "#6A6A6A",
							fontFamily: "DMMono-Regular",
						},
					}}
					onChange={(e) => {
						setMail(e.target.value);
					}}
					value={mail}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
							setTimeout(() => {
								dateRef.current.focus();
							}, 100);
						}
					}}
				/>
			</Box>
			<div style={{ position: "relative" }}>
				<Box
					component="form"
					sx={{
						"& > :not(style)": { mb: 4, width: "100%" },
					}}
					noValidate
					autoComplete="off"
					// sx={{ width: 500 }}
				>
					{/* <TextField
						ref={dateRef}
						// type={isDate ? "date" : "text"}
						type={isDate}
						color="success"
						id="standard-basic"
						label="Birth Date"
						variant="standard"
						InputProps={{
							style: { fontSize: 20 },
						}}
						InputLabelProps={{
							style: {
								fontSize: 20,
								color: "#6A6A6A",
								fontFamily: "DMMono-Regular",
								shrink: true,
							},
						}}
						onChange={(e) => {
							handleDateChange(e.target.value);
						}}
						onFocus={() => {
							// setDate((prev) => true);
							setDate("date");
						}}
						onBlur={() => {
							if (!dob) {
								// setDate((prev) => false);
								setDate("text");
							}
						}}
						value={dob}
					/> */}
					<TextField
						inputRef={dateRef}
						color="success"
						id="standard-basic"
						label="Age"
						variant="standard"
						type="number"
						InputProps={{
							style: {
								fontSize: 20,
								fontFamily: "Agatho-Bold",
								lineHeight: 1.5,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 20,
								color: "#6A6A6A",
								fontFamily: "DMMono-Regular",
							},
						}}
						onChange={(e) => {
							setDob(e.target.value);
						}}
						value={dob}
						onKeyPress={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								registerAccount();
							}
						}}
						onWheel={(e) => e.target.blur()}
					/>
					{/* <Icon
                        onClick={() => {
                            // setDate(true);
                            handleInterviewDateClick();
                        }}
                        src={
                            require("./../../../assets/icons/calendar.svg")
                                .default
                        }
                        alt="icon"
                    /> */}
				</Box>
			</div>
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar
					open={error ? true : false}
					autoHideDuration={3000}
					onClose={handleClose}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<Alert
						onClose={handleClose}
						severity="error"
						sx={{ width: "100%" }}
					>
						{error}
					</Alert>
				</Snackbar>
			</Stack>
		</div>
	);
};

export default forwardRef(Form1);

const Icon = styled.img`
	position: absolute;
	height: 20px;
	width: 20px !important;
	margin: 0 !important;
	top: 20px;
	right: 10px;
`;
const HeadingDiv = styled.div`
	user-select: none;
	/* position: relative; */
	/* margin-bottom: 32px; */
`;
const Heading = styled.h1`
	font-family: "Palatino-Regular";
	font-size: 26px;
	margin-bottom: 30px;
	color: #000;
	@media (max-width: 640px) {
	}
	@media (max-width: 370px) {
		margin-bottom: 15px;
	}
`;
