import React, {
	useContext,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import {
	useNavigate,
	useOutletContext,
	useParams,
	useLocation,
} from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import styled from "styled-components";

import CheckBoxCustom from "../../includes/CheckBoxCustom";
import { Context, defaultValue } from "../../../contexts/Store";
import { baseConfig } from "../../../axiosConfig";
import CheckBoxCustomDefault from "../../includes/CheckBoxCustomDefault";
import ConfirmationModal from "../../includes/modals/ConfirmationModal";

export default function Form6() {
	// Router constants
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [{ outletRef, setLoading }] = useOutletContext();
	const { state, dispatch } = useContext(Context);
	const [errorText, setErrorText] = useState(false);

	const search_path = location?.search;
	const customerId = new URLSearchParams(search_path).get("c");

	//modal
	const [isConfirmationModal, setConfirmationModal] = useState(false);

	useEffect(() => {
		const getBodyConditions = () => {
			baseConfig
				.get("users/get-body-conditions/")
				.then((response) => {
					const { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setMedicalConditions(
							data.map((item) => {
								if (
									state.profile.body_conditions.includes(
										item.id
									)
								) {
									item.is_selected = true;
								}
								return item;
							})
						);
					} else {
					}
				})
				.catch((error) => {});
		};
		getBodyConditions();
	}, []);

	const [isActive, setActive] = useState(false);
	const [medicalDetails, setMedicalConditions] = useState([]);
	const [otherMedicalConditions, setOtherMedicalConditions] = useState("");
	const [isOtherMedicalConditions, setIsOtherMedicalConditions] =
		useState(false);
	const [error, setError] = useState("");

	const [selectedItems, setSelectedItems] = useState(
		state.profile.body_conditions
	);

	const onClickHandler = (item) => {
		selectedItems.find((element) => element === item)
			? setSelectedItems(selectedItems.filter((elem) => elem !== item))
			: setSelectedItems([...selectedItems, item]);
	};

	const update = (value) => {
		const newArr = state.profile.body_conditions;

		if (newArr.find((element) => element === "none")) {
			const newArr = [];

			newArr.find((element) => element === value)
				? newArr.splice(newArr.indexOf(value), 1)
				: newArr.push(value);

			dispatch({
				type: "UPDATE_PROFILE",
				profile: {
					body_conditions: newArr,
				},
			});
		} else {
			newArr.find((element) => element === value)
				? newArr.splice(newArr.indexOf(value), 1)
				: newArr.push(value);

			dispatch({
				type: "UPDATE_PROFILE",
				profile: {
					body_conditions: newArr,
				},
			});
		}
	};

	const updateToNone = (value) => {
		// setSelectedItems([...selectedItems, value]);
		let newArr = state.profile.body_conditions;
		newArr.find((element) => element === value)
			? (newArr = [])
			: (newArr = ["none"]);

		setIsOtherMedicalConditions(false);

		// newArr.find((element) => element === value)
		// 	? newArr.splice(newArr.indexOf(value), 1)
		// 	: // newArr.filter((item) => item !== value)

		// 	  newArr.push(value);
		// return newArr;

		// newArr.push(value);

		// state.profile.body_conditions.find((element)=> element === value) ?
		// setSelectedItems
		dispatch({
			type: "UPDATE_PROFILE",
			profile: {
				body_conditions: newArr,
			},
		});
	};

	// Handle form submit
	const submitProfile = () => {
		setErrorText(false);
		const profile = {
			...state.profile,
			// body_conditions: selectedItems,
			body_conditions: [],

			other_medical_condition: otherMedicalConditions,
		};

		// dispatch({
		// 	type: "UPDATE_PROFILE",
		// 	profile: profile,
		// });

		if (isActive) {
			const newArr = state.profile.body_conditions;
			if (newArr.length == 0 && !isOtherMedicalConditions) {
				alert("Select an option below");
			} else {
				if (newArr.find((element) => element === "none")) {
					setLoading(true);
					baseConfig
						.post(`users/update/${id}/`, profile)
						.then((response) => {
							const { StatusCode, data, message } = response.data;
							if (StatusCode === 6000) {
								localStorage.clear();
								window.location = `/questions/${data.id}`;
								// localStorage.clear();
								// navigate(`/questions/${data.id}`);
								// dispatch({
								// 	type: "UPDATE_PROFILE",
								// 	bodyParts: defaultValue.bodyParts,
								// 	profile: defaultValue.profile,
								// 	user_details: defaultValue.user_details,
								// });
								setLoading(false);
							} else {
								setLoading(false);
								setError(message);
							}
							// setIsLoading(false);
						})
						.catch((error) => {
							setError(
								"An error occurred. Please try again later."
							);
							setLoading(false);
							// setIsLoading(false);
						});
				} else {
					setConfirmationModal(true);
				}
			}
		} else {
			setErrorText(true);
		}
	};

	const handleClose = () => {
		setError("");
	};

	useImperativeHandle(outletRef, () => ({
		submitProfile,
	}));

	useEffect(() => {
		if (isActive) {
			setErrorText(false);
		}
	}, [isActive]);

	return (
		<>
			<Container>
				<HeadingDiv>
					<Heading>Specify below details</Heading>
				</HeadingDiv>

				<CheckBoxContainer>
					{medicalDetails.map((data, i) => (
						<CheckBoxCustom
							key={i}
							item={data.title}
							onClick={() => {
								// onClickHandler(data.id);
								update(data.id);
							}}
							id={data.id}
						/>
					))}

					<CheckBoxCustomDefault
						item="Other Medical Conditions"
						onClick={() => {
							setIsOtherMedicalConditions((prev) => !prev);
							const newArr = state.profile.body_conditions;
							if (newArr.find((element) => element === "none")) {
								const newArr = [];
								dispatch({
									type: "UPDATE_PROFILE",
									profile: {
										body_conditions: newArr,
									},
								});
							}
						}}
						grid_column={" 1 / 3"}
						is_selected={isOtherMedicalConditions}
						margin={
							isOtherMedicalConditions ? 0 : "0px 0px 20px 0px"
						}
					/>
					{isOtherMedicalConditions && (
						<InputBox>
							<Box
								component="form"
								sx={{
									"& > :not(style)": {
										mb: isOtherMedicalConditions ? 3 : 0,
										width: "100%",
									},
								}}
								noValidate
								autoComplete="off"
								// sx={{ width: 500 }}
							>
								<TextField
									color="success"
									id="standard-basic"
									label="Other Details (Enter Here)"
									variant="standard"
									type="text"
									InputProps={{
										style: {
											fontSize: 15,
											fontFamily: "Agatho-Bold",
											lineHeight: 1.5,
										},
									}}
									InputLabelProps={{
										style: {
											fontSize: 15,
											color: "#6A6A6A",
											fontFamily: "DMMono-Regular",
										},
									}}
									onChange={(e) => {
										setOtherMedicalConditions(
											e.target.value
										);
									}}
									value={otherMedicalConditions}
								/>
							</Box>
						</InputBox>
					)}
					<CheckBoxCustom
						item={"None"}
						onClick={() => {
							// onClickHandler(data.id);
							updateToNone("none");
						}}
						id={"none"}
					/>
				</CheckBoxContainer>

				<Bottom>
					<CheckBoxCustomDefault
						item={
							"I hereby declare that the above details are accurate"
						}
						onClick={() => {
							setActive((prev) => !prev);
						}}
						is_selected={isActive}
						margin={`0px 0px 20px 0px`}
					/>
					<div style={{ height: "40px" }}>
						{errorText && (
							<ErrorMsg>Agree the terms to continue</ErrorMsg>
						)}
						{isActive && (
							<CustomerCode>
								Customer code - {customerId}
							</CustomerCode>
						)}
					</div>
				</Bottom>
			</Container>
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar
					open={error ? true : false}
					autoHideDuration={3000}
					onClose={handleClose}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<Alert
						onClose={handleClose}
						severity="error"
						sx={{ width: "100%" }}
					>
						{error}
					</Alert>
				</Snackbar>
			</Stack>
			{isConfirmationModal && (
				<ConfirmationModal setModal={setConfirmationModal} />
			)}
		</>
	);
}
const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const CheckBoxContainer = styled.div`
	display: grid;
	grid-template-columns: 1.5fr 1fr;
	position: relative;
	@media (max-width: 460px) {
		display: block;
	}
`;
const Bottom = styled.div`
	/* margin: 200px 0 40px; */
	position: relative;
	margin-top: 100px;
	@media (max-width: 768px) {
		/* margin-bottom: 20px; */
	}
`;
const CustomerCode = styled.p`
	font-size: 15px;
	line-height: 26px;
	color: #6a6a6a;
	font-family: "DMMono-Regular";
	margin-bottom: 10px;
	/* position: absolute; */
	/* top: 70px; */
	@media (max-width: 768px) {
		font-size: 16px;
	}
`;
const ErrorMsg = styled(CustomerCode)`
	font-size: 15px;
	color: red;
`;
const InputBox = styled.div`
	/* position: absolute; */
	width: 100%;
	/* bottom: -70px; */
	/* margin-top: 10px; */
	grid-column: 1/3;
	width: 80%;
	@media (max-width: 768px) {
		width: 100%;
	}
`;
const HeadingDiv = styled.div`
	user-select: none;
	width: 100%;
`;
const Heading = styled.h1`
	font-family: "Palatino-Regular";
	font-size: 26px;
	margin-bottom: 30px;
	color: #000;
	@media (max-width: 640px) {
		font-size: 23px;
	}
	@media (max-width: 375px) {
		margin-bottom: 10px;
	}
`;
