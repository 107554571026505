import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { baseConfig } from "../../../axiosConfig";
import Button from "../../includes/Button";
import { THEME_COLORS } from "../../../configData";
import Lottie from "react-lottie";
import { useParams, useNavigate } from "react-router-dom";
import animationData from "./../../../assets/lotties/button-loader.json";
import { Context } from "../../../contexts/Store";

export default function Question() {
	const { id } = useParams();
	const [questionNo, setQuestionNo] = useState(1);
	const [questionListArray, setQuestionListArray] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [isNextButtonActive, setNextButtonActive] = useState(true);
	const navigate = useNavigate();
	const { state, dispatch } = useContext(Context);
	const [selectedAnswer, setSelectedAnswer] = useState("");

	//post fields
	const [lastQuestionID, setLastQuestionID] = useState("");
	const [lastAnswerID, setLastAnswerID] = useState("");
	const [submitLoading, setSubmitLoading] = useState(false);
	const [title, setTitle] = useState("Enter your details");

	//get questions
	const getQuestions = () => {
		setLoading(true);
		baseConfig
			.get(`users/get-questions/`, {
				params: {
					customer_id: id,
				},
			})

			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setQuestionListArray(data);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	const nextButton = () => {
		if (isNextButtonActive) {
			setQuestionNo((prev) => prev + 1);
		}
	};

	const previousButton = () => {
		setQuestionNo((prev) => prev - 1);
	};

	//handle answers
	const handleAnswers = (question, answer) => {
		// if (answerId) {
		setSubmitLoading(true);
		var formdata = new FormData();
		formdata.append("customer_id", id);
		formdata.append("question_id", question);
		formdata.append("option_id", answer);

		baseConfig
			.post(`users/submit-answer/`, formdata, {})
			.then((response) => {
				const { StatusCode, message, data } = response.data;
				if (StatusCode === 6000) {
					if (questionNo === questionListArray.length) {
						getQuestions();
						setSubmitLoading(false);
						navigate(`/result/${id}`);
					} else {
						setQuestionNo((prev) => prev + 1);
						getQuestions();
						setSubmitLoading(false);
					}
				}
			})
			.catch((error, response) => {
				setSubmitLoading(false);
			});
	};

	useEffect(() => {
		getQuestions();
	}, []);

	useEffect(() => {
		// if (questionNo === questionListArray.length) {
		// 	setNextButtonActive(true);
		// 	questionListArray.map((data) => {
		// 		if (data.auto_id === questionNo) {
		// 			if (data.answer === null) {
		// 				setLastQuestionID("");
		// 				setLastAnswerID("");
		// 			} else {
		// 				setLastQuestionID(data.id);
		// 				setLastAnswerID(data.answer);
		// 			}
		// 		}
		// 	});
		// } else {
		questionListArray.map((data) => {
			if (data.auto_id === questionNo) {
				if (data.answer === null) {
					// setAnswerId(data.answer);
					// setQuestionId(data.id);
					setNextButtonActive(false);
				} else {
					setNextButtonActive(true);
				}
			}
		});
		// }
	}, [questionNo, isLoading]);

	const submit = () => {
		if (!submitLoading) {
			// handleAnswers(lastQuestionID, lastAnswerID);
			// navigate(`/result/${id}`);
		}
	};

	return (
		<MainContainer>
			<Body>
				{!isLoading ? (
					<>
						{questionListArray.map(
							(data, i) =>
								data.auto_id === questionNo && (
									<div key={i}>
										<Container>
											<CancelBtn>
												<Qnumber>
													Question {questionNo}/
													{questionListArray.length}
												</Qnumber>
												<CancelDiv
													// to={"/start/"}
													onClick={(e) => {
														if (
															window.confirm(
																"Are you sure want to exit?"
															)
														) {
															// navigate("/");
															e.preventDefault();
															localStorage.clear();
															window.location =
																"/";
														}
													}}
												>
													<BackButton>
														Cancel
													</BackButton>
												</CancelDiv>
											</CancelBtn>

											<QuestionSingle>
												{data.question}
											</QuestionSingle>
											<OptionsContainer>
												{data.options.map(
													(item, ind) => (
														// <>
														<Option
															key={ind}
															onClick={(e) => {
																e.preventDefault();
																if (
																	!submitLoading
																) {
																	setSelectedAnswer(
																		item.option_id
																	);
																	// if (
																	// 	questionNo ===
																	// 	questionListArray.length
																	// ) {
																	// 	setLastQuestionID(
																	// 		data.id
																	// 	);
																	// 	setLastAnswerID(
																	// 		item.option_id
																	// 	);
																	// } else {
																	handleAnswers(
																		data.id,
																		item.option_id
																	);
																	// }
																}
															}}
															className={
																(item.answer &&
																	"selected") ||
																(selectedAnswer ===
																	item.option_id &&
																	"selected")
															}
															style={
																{
																	// background:
																	// 	answerId ===
																	// 	item.option_id
																	// 		? "#fff"
																	// 		: THEME_COLORS.background_1,
																	// background: item.answer
																	// 	? "#fff"
																	// 	: THEME_COLORS.background_1,
																}
															}
														>
															{/* {item.option} */}
															<Text>
																{item.option}
															</Text>
															<ImageDiv>
																<Image
																	// src={require("./../../../assets/images/eye.png")}
																	src={
																		item.image
																	}
																	alt="image"
																/>
															</ImageDiv>
														</Option>
														// <Option>ff</Option>
														// <Option>
														// 	ffuu
														// </Option>
														// </>
													)
												)}
											</OptionsContainer>
										</Container>
										{/* <ButtonDiv>
										{questionNo !== 1 && (
											<Button
												color={THEME_COLORS.secondary_2}
												border={
													THEME_COLORS.secondary_5
												}
												// background={THEME_COLORS.background_1}
												text="Previous"
												arrow="left"
												width="48%"
												onClick={previousButton}
											/>
										)}
										<Button
											color={THEME_COLORS.secondary_2}
											border={THEME_COLORS.secondary_5}
											// background={THEME_COLORS.secondary_2}
											opacityVal={
												isNextButtonActive ? 1 : 0.4
											}
											text={
												questionNo <=
												questionListArray.length - 1
													? "Next"
													: "Submit"
											}
											arrow={
												questionNo <=
													questionListArray.length -
														1 && !submitLoading
													? "right"
													: null
											}
											width={
												questionNo === 1
													? "100%"
													: "48%"
											}
											onClick={
												questionNo <=
												questionListArray.length - 1
													? nextButton
													: submit
											}
											isLoading={submitLoading}
										/>
									</ButtonDiv> */}
									</div>
								)
						)}
					</>
				) : (
					<LoaderDiv>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								animationData: animationData,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
							height={120}
							width={120}
							//  eventListeners={eventListeners}
						/>
					</LoaderDiv>
				)}
			</Body>

			<ButtonDiv>
				{questionNo !== 1 && (
					<Button
						color={THEME_COLORS.secondary_2}
						border={THEME_COLORS.secondary_5}
						// background={THEME_COLORS.background_1}
						text="Previous"
						arrow="left"
						width="48%"
						onClick={previousButton}
					/>
				)}
				<Button
					color={THEME_COLORS.secondary_2}
					border={THEME_COLORS.secondary_5}
					// background={THEME_COLORS.secondary_2}
					opacityVal={isNextButtonActive || submitLoading ? 1 : 0.4}
					text={
						questionNo <= questionListArray.length - 1
							? "Next"
							: "Submit"
					}
					arrow={
						questionNo <= questionListArray.length - 1 &&
						!submitLoading
							? "right"
							: null
					}
					width={questionNo === 1 ? "100%" : "48%"}
					onClick={
						questionNo <= questionListArray.length - 1
							? nextButton
							: submit
					}
					isLoading={submitLoading}
				/>
			</ButtonDiv>
		</MainContainer>
	);
	// ) : (
	// </>
	// ) : (
	// 	<LoaderDiv>
	// 		<Lottie
	// 			options={{
	// 				loop: true,
	// 				autoplay: true,
	// 				animationData: animationData,
	// 				rendererSettings: {
	// 					preserveAspectRatio: "xMidYMid slice",
	// 				},
	// 			}}
	// 			height={120}
	// 			width={120}
	// 			// eventListeners={eventListeners}
	// 		/>
	// 	</LoaderDiv>
	// );
	// );
}
const MainContainer = styled.div`
	/* height: 100%; */
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
`;
const Container = styled.div`
	overflow-y: scroll;

	/* height: calc(100vh - 300px); */
	/* min-height: calc(100vh - 300px); */
`;
const Body = styled.div`
	/* height: calc(100vh - 300px);
	min-height: calc(100vh - 300px); */

	/* overflow-y: scroll; */
	/* min-height: calc(100vh - 360px); */
	/* height: calc(100vh - 360px); */

	@media (max-width: 460px) {
		/* overflow-y: scroll; */
		/* min-height: calc(100vh - 230px); */
		/* height: calc(100vh - 230px); */
	}
`;
const Qnumber = styled.h3`
	font-size: 20px;
	line-height: 26px;
	font-family: "DMMono-Regular";
	color: #6a6a6a;
	@media (max-width: 640px) {
		font-size: 18px;
	}
	@media (max-width: 460px) {
		font-size: 16px;
	}
`;
const CancelBtn = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 10px 0 0;
`;
const CancelDiv = styled.div`
	cursor: pointer;
	user-select: none;
`;
const BackButton = styled.h4`
	color: ${THEME_COLORS.secondary_1};
	font-family: "Agatho-Medium";
	font-size: 22px;
	text-decoration: underline;
	@media (max-width: 980px) {
		font-size: 24px;
	}
	@media (max-width: 640px) {
		font-size: 21px;
	}
	@media (max-width: 460px) {
		font-size: 20px;
	}
`;

const QuestionSingle = styled.h2`
	font-size: 22px;
	line-height: 28px;
	color: #000000;
	font-family: "Agatho-Medium";
	margin: 10px 0px 15px;
	@media (max-width: 1200px) {
		font-size: 17px;
		margin: 10px 0;
	}
`;
const OptionsContainer = styled.div`
	/* margin-bottom: 30px; */
	display: flex;
	flex-direction: column;
	max-height: 470px;
`;
const Option = styled.button`
	/* padding: 20px 20px 30px 20px; */
	padding: 8px 8px 8px 20px;
	font-size: 26px;
	line-height: 28px;
	color: #6e421b;
	border: 2px solid #fadbb5;
	border-radius: 16px;
	cursor: pointer;
	text-align: unset;
	margin-bottom: 10px;
	font-family: "Agatho-Regular";
	/* min-height: 180px; */
	display: flex;
	align-items: center;
	justify-content: space-between;
	&.selected {
		background: #fff;
	}
	:focus {
		/* background: #fff; */
	}
	:focus-visible {
		/* background: #fff; */
	}
	:last-child {
		margin-bottom: 0;
	}
	/* @media (max-width: 640px) {
		font-size: 24px;
	}
	@media (max-width: 460px) {
		font-size: 20px;
	} */
`;
const Text = styled.p`
	color: #6e421b;
	font-family: "Agatho-Regular";
	font-size: 22px;
	line-height: 30px;
	margin-right: 10px;
	@media (max-width: 640px) {
		font-size: 24px;
		/* padding: 15px 15px 20px 15px; */
	}
	@media (max-width: 460px) {
		font-size: 15px;
		line-height: 20px;
	}
`;
const ImageDiv = styled.div`
	height: 120px;
	@media (max-width: 420px) {
		height: 100px;
	}
`;
const Image = styled.img`
	height: 100%;
	border-radius: 12px;
	/* width: 100%; */
`;

const ButtonDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 10px;
`;
const LoaderDiv = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;
