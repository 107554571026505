// import React from "react";
// import "./Modal.css";

// function ConfirmationModal({ setOpenModal }) {
// 	return (
// 		<div className="modalBackground">
// 			<div className="modalContainer">
// 				<div className="titleCloseBtn">
// 					<button
// 						onClick={() => {
// 							setOpenModal(false);
// 						}}
// 					>
// 						X
// 					</button>
// 				</div>
// 				<div className="title">
// 					<h1>Are You Sure You Want to Continue?</h1>
// 				</div>
// 				<div className="body">
// 					<p>
// 						The next page looks amazing. Hope you want to go there!
// 					</p>
// 				</div>
// 				<div className="footer">
// 					<button
// 						onClick={() => {
// 							setOpenModal(false);
// 						}}
// 						id="cancelBtn"
// 					>
// 						Cancel
// 					</button>
// 					<button>Continue</button>
// 				</div>
// 			</div>
// 		</div>
// 	);
// }

// export default ConfirmationModal;

import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../configData";
// import { Link } from "react-router-dom";
import BackgroundBg from "./../../../assets/images/modal-bg-2.png";

function ConfirmationModal({ setModal, isLogOutModal }) {
	return (
		<BackContainer>
			<Overlay
			// onClick={() => {
			// 	setModal(false);
			// }}
			></Overlay>
			<Modal>
				<Container>
					{/* <Title>Are you sure you want to Logout?</Title> */}
					<TopContainer>
						<BgContainer />
					</TopContainer>
					<BottomContainer>
						<TitleDiv>
							<Heading>You need a consultation</Heading>
							<Paragraph>
								You need a consultation from our doctor for
								better experience
							</Paragraph>
						</TitleDiv>
						<ButtonDiv>
							<Button
								onClick={(e) => {
									e.preventDefault();
									setModal(false);
								}}
							>
								Okay
							</Button>
						</ButtonDiv>
						{/* <Button
							onClick={() => {
								setModal(false);
							}}
							style={{
								display: "flex",
								alignItems: "center",
								margin: "0 22px",
							}}
							backgroundColor="#F4F4F4"
							fontSize="15px"
							padding="21px 70px"
						>
							<Text color="#522e92" fontSize="15px">
								Cancel
							</Text>
						</Button>

						<Button
							onClick={(e) => {
								e.preventDefault();
								localStorage.clear();
								window.location = "/";
							}}
							style={{
								display: "flex",
								alignItems: "center",
								margin: "0 22px",
							}}
							backgroundColor="#5A2D8F"
							fontSize="15px"
							padding="21px 70px"
						>
							<Text color="fff" fontSize="15px">
								Logout
							</Text>
						</Button> */}
					</BottomContainer>
				</Container>
			</Modal>
		</BackContainer>
	);
}
export default ConfirmationModal;

const BackContainer = styled.div`
	position: fixed;
	transition: 0.3s;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	left: 0;
	top: 0px;
	background: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(2px);
`;
const Overlay = styled.div`
	position: fixed;
	left: 0;
	top: 0px;
	width: 100%;
	/* cursor: pointer; */
	height: 100vh;
`;
const Modal = styled.div`
	margin: 0 auto;
	background: #fff;
	left: 50%;
	top: 45%;
	transform: translate(-50%, -50%);
	position: absolute;
	border-radius: 23px;
	transition: 0.5s;
	z-index: 101;
`;
const Container = styled.div`
	width: 400px;
	/* padding: 30px 15px; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 24px;
`;
const TopContainer = styled.div`
	background-color: #fef5e9;
	/* background-image: url(""); */
	height: 260px;
	width: 100%;
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
`;
const BgContainer = styled.div`
	background: url(${BackgroundBg}) no-repeat bottom center;
	background-size: cover;
	height: 100%;
	width: 100%;
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
	/* padding-top: 30px; */
`;
const Title = styled.h4`
	text-align: center;
	font-size: 18px;
	margin-bottom: 20px;
`;
const BottomContainer = styled.div`
	/* text-align: center; */
	/* font-size: 18px; */
	/* display: flex; */
	/* justify-content: space-between; */
	padding: 40px;
`;
const TitleDiv = styled.div`
	margin-bottom: 30px;
`;
const Heading = styled.h3`
	font-size: 28px;
	text-align: center;
	color: #000;
	margin-bottom: 10px;
`;
const Paragraph = styled.p`
	color: #6a6a6a;
	font-size: 18px;
	text-align: center;
`;

const ButtonDiv = styled.div``;
const Text = styled.h4`
	font-size: "15px";
	color: ${(props) => (props.color ? props.color : "white")}; ;
`;

const Button = styled.a`
	background-color: ${(props) =>
		props.backgroundColor
			? props.backgroundColor
			: THEME_COLORS.secondary_5};
	font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
	color: ${(props) => (props.color ? props.color : THEME_COLORS.secondary_2)};
	// padding: ${(props) => (props.padding ? props.padding : "15px 40px")};
	border: none;
	border-radius: 36px;
	text-align: center;
	display: inline-block;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	cursor: pointer;
`;
