const Reducer = (state, action) => {
	switch (action.type) {
		case "SET_BODY_PARTS":
			return { ...state, bodyParts: action.bodyParts };
		case "UPDATE_BODY_PARTS":
			const bodyParts = state.bodyParts.map((bodyPart) =>
				bodyPart.type === action.bodyPart.type
					? { ...bodyPart, ...action.bodyPart }
					: bodyPart
			);
			return { ...state, bodyParts };
		case "UPDATE_PROFILE":
			return {
				...state,
				profile: { ...state.profile, ...action.profile },
			};
		case "UPDATE_USER_DETAILS":
			return {
				...state,
				user_details: { ...state.user_details, ...action.user_details },
			};
		default:
			return state;
	}
};

export default Reducer;
