import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

import Head from "./../assets/images/body-parts/Head.svg";
import Neck from "./../assets/images/body-parts/Neck.svg";
import Shoulder from "./../assets/images/body-parts/Shoulder.svg";
import Arm from "./../assets/images/body-parts/Arm.svg";
import Back from "./../assets/images/body-parts/Back.svg";
import Waist from "./../assets/images/body-parts/Waist.svg";
import Hand from "./../assets/images/body-parts/Hand.svg";
import Thigh from "./../assets/images/body-parts/Thigh.svg";
import Calf from "./../assets/images/body-parts/Calf.svg";
import Foot from "./../assets/images/body-parts/Foot.svg";

export const defaultValue = {
	bodyParts: [
		{
			type: "Head",
			id: "head",
			is_selected: false,
			image: Head,
		},
		{
			type: "Neck",
			id: "neck",
			is_selected: false,
			image: Neck,
		},
		{
			type: "Shoulder",
			id: "shoulder",
			is_selected: false,
			image: Shoulder,
		},
		{
			type: "Arm",
			id: "arm",
			is_selected: false,
			image: Arm,
		},
		{
			type: "Back",
			id: "back",
			is_selected: false,
			image: Back,
		},
		{
			type: "Waist",
			id: "waist",
			is_selected: false,
			image: Waist,
		},
		{
			type: "Hand",
			id: "hand",
			is_selected: false,
			image: Hand,
		},
		{
			type: "Thigh",
			id: "thigh",
			is_selected: false,
			image: Thigh,
		},
		{
			type: "Calf",
			id: "calf",
			is_selected: false,
			image: Calf,
		},
		{
			type: "Foot",
			id: "foot",
			is_selected: false,
			image: Foot,
		},
	],
	profile: {
		gender: "",
		occupation: "",
		marital_status: "",
		address: "",
		reference: "",
		pressure_preference: "",
		body_parts: [],
		body_conditions: [],
	},
	user_details: {
		user_id: "",
	},
};

const initialState = {
	bodyParts: [
		{
			type: "Head",
			id: "head",
			is_selected: false,
			image: Head,
		},
		{
			type: "Neck",
			id: "neck",
			is_selected: false,
			image: Neck,
		},
		{
			type: "Shoulder",
			id: "shoulder",
			is_selected: false,
			image: Shoulder,
		},
		{
			type: "Arm",
			id: "arm",
			is_selected: false,
			image: Arm,
		},
		{
			type: "Back",
			id: "back",
			is_selected: false,
			image: Back,
		},
		{
			type: "Waist",
			id: "waist",
			is_selected: false,
			image: Waist,
		},
		{
			type: "Hand",
			id: "hand",
			is_selected: false,
			image: Hand,
		},
		{
			type: "Thigh",
			id: "thigh",
			is_selected: false,
			image: Thigh,
		},
		{
			type: "Calf",
			id: "calf",
			is_selected: false,
			image: Calf,
		},
		{
			type: "Foot",
			id: "foot",
			is_selected: false,
			image: Foot,
		},
	],
	profile: {
		gender: "",
		occupation: "",
		marital_status: "",
		address: "",
		reference: "",
		pressure_preference: "",
		body_parts: [],
		body_conditions: [],
	},
	user_details: {
		user_id: "",
	},
};

const Store = ({ children }) => {
	const [state, dispatch] = useReducer(Reducer, initialState);

	return (
		<Context.Provider value={{ state, dispatch }}>
			{children}
		</Context.Provider>
	);
};

export const Context = createContext(initialState);

export default Store;
