import styled from "styled-components";
import React, {
	useContext,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import { THEME_COLORS } from "../../../configData";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import {
	useNavigate,
	useOutletContext,
	useParams,
	useLocation,
} from "react-router-dom";
import { Context } from "../../../contexts/Store";

//alert
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

export default function Form2() {
	// Router constants
	const { id } = useParams();
	const navigate = useNavigate();
	const [{ outletRef }] = useOutletContext();
	const location = useLocation();

	const { state, dispatch } = useContext(Context);

	const [genderOptions] = useState(["male", "female"]);
	const [gender, setGender] = useState(state.profile.gender);
	const [maritalStatusOptions] = useState(["single", "married"]);
	const [maritalStatus, setMaritalStatus] = useState(
		state.profile.marital_status
	);
	const [occupation, setOccupation] = useState(state.profile.occupation);
	const [address, setAddress] = useState(state.profile.address);
	const [anniversaryDate, setAnniversaryDate] = useState(null);
	const [error, setError] = useState("");

	// Date picker
	const dateRef = useRef(null);
	const [isDate, setDate] = useState("");

	// Handle date change
	const handleDateChange = (date) => {
		setAnniversaryDate(date);
	};

	useEffect(() => {
		dateRef.current?.focus();
	}, [dateRef.current]);

	const onClickGenderHandler = (item) => {
		setGender(item);
	};

	const onClickMaritalStatusHandler = (item) => {
		setMaritalStatus(item);
	};

	// Handle form submit
	const updateProfile = () => {
		if (gender) {
			dispatch({
				type: "UPDATE_PROFILE",
				profile: {
					gender: gender,
					marital_status: maritalStatus,
					occupation: occupation,
					address: address,
					anniversary_date: anniversaryDate,
				},
			});
			navigate({
				pathname: `/prakriti/know-us/${id}/`,
				search: location.search,
			});
		} else {
			setError("Please select your gender");
		}
	};

	useImperativeHandle(outletRef, () => ({
		updateProfile,
	}));

	const handleClose = () => setError("");

	return (
		<Container>
			<HeadingDiv>
				<Heading>Enter Your Details</Heading>
			</HeadingDiv>
			<SingleForm>
				<Title>Gender*</Title>{" "}
				<SelectContainer>
					{genderOptions.map((data) => (
						<RadioInput
							key={data}
							onClick={() => {
								onClickGenderHandler(data);
							}}
						>
							<RadioButtonChecked>
								{gender === data && <InnerCircle />}
							</RadioButtonChecked>
							<OptionText>{data}</OptionText>
						</RadioInput>
					))}
				</SelectContainer>
			</SingleForm>
			<Box
				component="form"
				sx={{
					"& > :not(style)": { mb: 4, width: "100%" },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					style={{
						fontFamily: "Agatho-Bold",
					}}
					color="success"
					id="standard-basic"
					label="Occupation"
					variant="standard"
					InputProps={{
						style: {
							fontSize: 20,
							fontFamily: "Agatho-Bold",
							lineHeight: 1.5,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 20,
							color: "#6A6A6A",
							fontFamily: "DMMono-Regular",
						},
					}}
					value={occupation}
					onChange={(e) => setOccupation(e.target.value)}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
						}
					}}
				/>
			</Box>
			<SingleForm>
				<Title>Marital Status</Title>
				<SelectContainer>
					{maritalStatusOptions.map((data) => (
						<RadioInput
							key={data}
							onClick={() => {
								onClickMaritalStatusHandler(data);
							}}
						>
							<RadioButtonChecked>
								{maritalStatus === data && <InnerCircle />}
							</RadioButtonChecked>
							<OptionText>{data}</OptionText>
						</RadioInput>
					))}
				</SelectContainer>
			</SingleForm>
			<SingleForm>
				{maritalStatus === "married" && (
					<div style={{ position: "relative" }}>
						<Box
							component="form"
							sx={{
								"& > :not(style)": { mb: 4, width: "100%" },
							}}
							noValidate
							autoComplete="off"
						>
							<TextField
								ref={dateRef}
								type={isDate}
								color="success"
								id="standard-basic"
								label="Anniversary Date"
								variant="standard"
								InputProps={{
									style: {
										fontSize: 20,
										// fontFamily: "Agatho-Bold",
										lineHeight: 1.5,
									},
									inputProps: {
										max: new Date().toLocaleDateString(
											"en-ca"
										),
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 20,
										color: "#6A6A6A",
										fontFamily: "DMMono-Regular",
										shrink: true,
									},
								}}
								onChange={(e) => {
									handleDateChange(e.target.value);
								}}
								onFocus={() => {
									setDate("date");
								}}
								onBlur={() => {
									if (!anniversaryDate) {
										setDate("text");
									}
								}}
								value={anniversaryDate}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										e.preventDefault();
									}
								}}
							/>
						</Box>
					</div>
				)}
			</SingleForm>
			<Box
				component="form"
				sx={{
					"& > :not(style)": { mb: 4, width: "100%" },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					style={{
						fontFamily: "Agatho-Bold",
					}}
					color="success"
					id="standard-basic"
					label="Address"
					variant="standard"
					InputProps={{
						style: {
							fontSize: 20,
							fontFamily: "Agatho-Bold",
							lineHeight: 1.5,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 20,
							color: "#6A6A6A",
							fontFamily: "DMMono-Regular",
						},
					}}
					value={address}
					onChange={(e) => setAddress(e.target.value)}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							e.preventDefault();
						}
					}}
				/>
			</Box>

			{/* alert box start */}
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar
					open={error ? true : false}
					autoHideDuration={3000}
					onClose={handleClose}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<Alert
						onClose={handleClose}
						severity="error"
						sx={{ width: "100%" }}
					>
						{error}
					</Alert>
				</Snackbar>
			</Stack>
			{/* alert box end */}
		</Container>
	);
}
const HeadingDiv = styled.div`
	user-select: none;
`;
const Heading = styled.h1`
	font-family: "Palatino-Regular";
	font-size: 26px;
	margin-bottom: 30px;
	color: #000;
	@media (max-width: 640px) {
	}
	@media (max-width: 370px) {
		margin-bottom: 15px;
	}
`;
const Container = styled.div``;
const SingleForm = styled.div`
	margin-bottom: 30px;
`;
const SelectContainer = styled.div`
	width: 60%;
	display: flex;
	justify-content: space-between;
	@media (max-width: 640px) {
		width: 70%;
	}
	@media (max-width: 460px) {
		width: 80%;
	}
`;
const RadioInput = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;
const Title = styled.h2`
	color: #6a6a6a;
	line-height: 26px;
	margin-bottom: 15px;
	font-size: 20px;
	font-family: "DMMono-Regular";
	@media (max-width: 768px) {
		font-size: 18px;
	}
`;
const RadioButtonChecked = styled.div`
	background-color: #fff;
	width: 18px;
	height: 18px;
	min-width: 18px;
	min-height: 18px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	border: 1px solid #6a6a6a;
	font-weight: 700;
	font-size: 30px;
	line-height: 32px;
	color: ${THEME_COLORS.primary_1};
	cursor: pointer;
`;
const InnerCircle = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: ${THEME_COLORS.primary_2};
`;
const OptionText = styled.h3`
	text-transform: capitalize;
	@media (max-width: 768px) {
		font-size: 18px;
	}
`;
