import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "./../../../configData";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

export default function StartBox() {
	const navigate = useNavigate();

	const toggle = () => {
		navigate("/start");
	};

	const [matches, setMatches] = useState(
		window.matchMedia("(min-width: 460px)").matches
	);

	useEffect(() => {
		window
			.matchMedia("(min-width: 460px)")
			.addEventListener("change", (e) => setMatches(e.matches));
	}, []);

	return (
		<Container>
			<ParagraphDiv>
				<Paragraph>
					As long as we are not living in harmony with nature and our
					constitution, we cannot expect ourselves to be really
					healed. Ayurveda gives us the means – David Frawley
				</Paragraph>
			</ParagraphDiv>
			<Button
				color={THEME_COLORS.secondary_2}
				border={THEME_COLORS.secondary_5}
				text="Get Started"
				arrow="right"
				onClick={toggle}
				width={!matches ? "100%" : ""}
			/>
		</Container>
	);
}

const Container = styled.div``;

const ParagraphDiv = styled.div`
	margin-bottom: 32px;
	@media (max-width: 320px) {
		margin-bottom: 20px;
	}
`;
const Paragraph = styled.p`
	font-family: "Agatho-Regular";
	font-size: 24px;
	color: #6a6a6a;
	@media (max-width: 768px) {
		font-size: 18px;
		text-align: center;
	}
	@media (max-width: 460px) {
		font-size: 16px;
	}
`;
