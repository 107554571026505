import React, { useContext, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import Strong from "../../../assets/images/strong.png";
import Medium from "../../../assets/images/medium.png";
import Soft from "../../../assets/images/soft.png";
import BGCard from "../../includes/BGCard";
import {
	useNavigate,
	useOutletContext,
	useParams,
	useLocation,
} from "react-router-dom";
import { Context } from "../../../contexts/Store";

export default function Form4() {
	// Router constants
	const { id } = useParams();
	const navigate = useNavigate();
	const [{ outletRef }] = useOutletContext();
	const location = useLocation();
	const { state, dispatch } = useContext(Context);

	const [pressurePreferences] = useState([
		{ type: "strong", bGImage: Strong },
		{ type: "medium", bGImage: Medium },
		{ type: "soft", bGImage: Soft },
	]);

	// const [pressurePreference, setPressurePreference] = useState(
	// 	state.profile.pressure_preference
	// );

	// Handle form submit
	const updateProfile = () => {
		// dispatch({
		// 	type: "UPDATE_PROFILE",
		// 	profile: {
		// 		pressure_preference: pressurePreference,
		// 	},
		// });
		// navigate(`/prakriti/body-parts/${id}/`);
		navigate({
			pathname: `/prakriti/body-parts/${id}/`,
			search: location.search,
		});
	};

	useImperativeHandle(outletRef, () => ({
		updateProfile,
	}));

	return (
		<Container>
			<BGCard cardContent={pressurePreferences} />
		</Container>
	);
}
const Container = styled.div`
	margin-bottom: 35px;
`;
