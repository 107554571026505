export const THEME_COLORS = {
	PRIMARY: "red",
	SECONDARY: "yellow",

	//primary
	primary_1: "#6E421B",
	primary_2: "#465B29",

	//secondary
	secondary_1: "#C37F53",
	secondary_2: "#FADBB5",
	secondary_3: "#A3705B",
	secondary_4: "#A94830",
	secondary_5: "#6B8336",
	secondary_6: "#D4C8B7",

	//background
	background_1: "#FFF5E9",

	//text color
	text_1: "#B0B0B0",
	text_2: "#6A6A6A",
	text_3: "#2D2D2D",
};
