import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Context } from "../../contexts/Store";

export default function BGCard({ cardContent }) {
	const { state, dispatch } = useContext(Context);

	const [pressurePreference, setPressurePreference] = useState(
		state.profile.pressure_preference
	);

	const update = (value) => {
		setPressurePreference(value);
		dispatch({
			type: "UPDATE_PROFILE",
			profile: {
				pressure_preference: value,
			},
		});
	};

	return (
		<OptionsContainer>
			<HeadingDiv>
				<Heading>Pressure Preference</Heading>
			</HeadingDiv>

			{cardContent.map((data) => (
				<Card
					className={pressurePreference === data.type ? "active" : ""}
					key={data.type}
					onClick={() => {
						update(data.type);
					}}
				>
					{data.type}
					<ImageContainer>
						<BgImage src={data.bGImage} />
					</ImageContainer>
				</Card>
			))}
		</OptionsContainer>
	);
}
const HeadingDiv = styled.div`
	user-select: none;
`;
const Heading = styled.h1`
	font-family: "Palatino-Regular";
	font-size: 26px;
	margin-bottom: 30px;
	color: #000;
	@media (max-width: 640px) {
		font-size: 23px;
	}
	@media (max-width: 375px) {
		margin-bottom: 10px;
	}
`;
const OptionsContainer = styled.div``;
const Card = styled.button`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background: #ffffff;
	border-radius: 16px;
	margin-bottom: 15px;
	font-size: 30px;
	line-height: 32px;
	letter-spacing: 0.025em;
	color: #6e421b;
	overflow: hidden;
	padding: 6px 6px 6px 50px;
	cursor: pointer;
	border: 3px solid #fff;
	font-family: "Agatho-Medium";
	text-transform: capitalize;
	height: 130px;
	:last-child {
		margin-bottom: 0;
	}
	:hover {
		/* background: rgb(11 63 122 / 8%); */
	}
	&.active {
		border: 3px solid #fadbb5;
	}
	&:focus {
		border: 3px solid #fadbb5;
	}

	@media (max-width: 820px) {
		font-size: 28px;
	}
	@media (max-width: 560px) {
		padding: 6px 6px 6px 40px;
		font-size: 26px;
	}
	@media (max-width: 460px) {
		padding: 4px 4px 4px 10px;
		font-size: 23px;
	}
	@media (max-width: 360px) {
		height: 100px;
	}
`;
const ImageContainer = styled.div`
	border-radius: 16px;
	height: 100%;

	/* @media (max-height: 900px) {
		width: 40%;
	}
	@media (max-height: 820px) {
		width: 60%;
	} */
`;
const BgImage = styled.img`
	border-radius: 10px;
	display: block;
	height: 100%;
	width: 100%;
`;
