import React, { useState } from "react";
import styled from "styled-components";
import { useOutletContext } from "react-router-dom";
import rating_on_1 from "../../../assets/images/rating-icons/rating-on-1.svg";
import rating_on_2 from "../../../assets/images/rating-icons/rating-on-2.svg";
import rating_on_3 from "../../../assets/images/rating-icons/rating-on-3.svg";
import rating_on_4 from "../../../assets/images/rating-icons/rating-on-4.svg";
import rating_on_5 from "../../../assets/images/rating-icons/rating-on-5.svg";
import rating_off_1 from "../../../assets/images/rating-icons/rating-off-1.svg";
import rating_off_2 from "../../../assets/images/rating-icons/rating-off-2.svg";
import rating_off_3 from "../../../assets/images/rating-icons/rating-off-3.svg";
import rating_off_4 from "../../../assets/images/rating-icons/rating-off-4.svg";
import rating_off_5 from "../../../assets/images/rating-icons/rating-off-5.svg";

export default function ReceptionForm() {
	const [{ pageStatus, resultObject, setResultObject, setRatingData }] =
		useOutletContext();

	const [ratingImages] = useState({
		rating_on_1: rating_on_1,
		rating_on_2: rating_on_2,
		rating_on_3: rating_on_3,
		rating_on_4: rating_on_4,
		rating_on_5: rating_on_5,
		rating_off_1: rating_off_1,
		rating_off_2: rating_off_2,
		rating_off_3: rating_off_3,
		rating_off_4: rating_off_4,
		rating_off_5: rating_off_5,
	});
	const [ratingMessage] = useState({
		1: { message: "Poor", color: "#FF0000" },
		2: { message: "Average", color: "#FF5C00" },
		3: { message: "Good", color: "#FF5C00" },
		4: { message: "Very Good", color: "#E9B84B" },
		5: { message: "Excellent", color: "#E9B84B" },
	});
	const [ratingPoints, setRatingPoints] = useState([
		{
			key: "booking_experience",
			name: "Booking Experience",
			value: 0,
			category: "reception",
		},
		{
			key: "check_in_experience",
			name: "Check In Experience",
			value: 0,
			category: "reception",
		},
		{
			key: "information_on_services",
			name: "Information On Services",
			value: 0,
			category: "reception",
		},
		{
			key: "information_on_benefits",
			name: "Information On Benefits",
			value: 0,
			category: "reception",
		},
		{
			key: "check_out_experience",
			name: "Check Out Experience",
			value: 0,
			category: "reception",
		},
		{
			key: "appearance",
			name: "Appearance",
			value: 0,
			category: "therapist",
		},
		{
			key: "personal_hygiene",
			name: "Personal Grooming & Hygiene",
			value: 0,
			category: "therapist",
		},
		{
			key: "therapy_technique",
			name: "Therapy Technique",
			value: 0,
			category: "therapist",
		},
		{
			key: "therapy_knowledge",
			name: "Therapy Knowledge",
			value: 0,
			category: "therapist",
		},
		{
			key: "concern_for_comfort",
			name: "Concern For Your Comfort",
			value: 0,
			category: "therapist",
		},
		{
			key: "recommendation_offered",
			name: "Recommendation Offered",
			value: 0,
			category: "therapist",
		},
		{
			key: "room_hygiene",
			name: "Treatment Room Hygiene",
			value: 0,
			category: "amenities",
		},
		{
			key: "guest_amenities",
			name: "Guest Amenities",
			value: 0,
			category: "amenities",
		},
		{
			key: "music",
			name: "Audio & Music",
			value: 0,
			category: "amenities",
		},
		{
			key: "lighting",
			name: "Lighting",
			value: 0,
			category: "amenities",
		},
		{
			key: "air_conditioning",
			name: "Air-conditioning & Heating",
			value: 0,
			category: "amenities",
		},
		{
			key: "shower_facility",
			name: "Shower Facility",
			value: 0,
			category: "amenities",
		},
		{
			key: "linen_quality",
			name: "Linen Quality",
			value: 0,
			category: "amenities",
		},
	]);

	const setRating = (value, item) => {
		setRatingPoints(
			ratingPoints.map((rating) => {
				if (rating.key === item.key) {
					setResultObject({
						...resultObject,
						[item.key]: value,
					});
					return {
						...rating,
						value: value,
						[item.key]: value,
					};
				}
				return rating;
			})
		);
		setRatingData(ratingPoints);
	};

	return (
		<Container>
			{ratingPoints
				.filter((point) =>
					pageStatus === "reception"
						? point.category === "reception"
						: pageStatus === "therapist"
						? point.category === "therapist"
						: pageStatus === "amenities"
						? point.category === "amenities"
						: null
				)
				.map((point) => (
					<RatingContainer key={point.key}>
						<Top>
							<Label>{point.name}</Label>
							<Rating color={ratingMessage[point.value]?.color}>
								{ratingMessage[point.value]?.message}
							</Rating>
						</Top>
						<Bottom>
							{[...Array(5)].map((_, i) => (
								<RatingIcon
									key={i}
									src={
										point.value === i + 1
											? ratingImages[`rating_on_${i + 1}`]
											: ratingImages[
													`rating_off_${i + 1}`
											  ]
									}
									onClick={() => {
										setRating(i + 1, point);
									}}
								/>
							))}
						</Bottom>
					</RatingContainer>
				))}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	/* height: 100%; */
	user-select: none;
	@media (max-width: 768px) {
		margin-top: 30px;
	}
`;
const RatingContainer = styled.div`
	margin-bottom: 15px;
`;
const Top = styled.div`
	display: flex;
	justify-content: space-between;
`;
const Label = styled.p`
	font-family: "DMMono-Regular";
	color: #6a6a6a;
	margin-bottom: 5px;
`;
const Rating = styled.span`
	color: ${(props) => props.color};
`;
const Bottom = styled.div`
	display: flex;
	justify-content: space-between;
`;
const RatingIcon = styled.img`
	display: block;
	width: 42.65px;
	cursor: pointer;
`;
