import React from "react";
import { THEME_COLORS } from "./../../configData";
import styled from "styled-components";
import Lottie from "react-lottie";

//svg
import LeftArrow from "./../../assets/icons/left-arrow-press.svg";
import RightArrow from "./../../assets/icons/right-arrow-press.svg";
import LeftArrowPress from "./../../assets/icons/left-arrow.svg";
import RightArrowPress from "./../../assets/icons/right-arrow.svg";
import animationData from "./../../assets/lotties/button-loader.json";

function Button({
	color,
	border,
	text,
	arrow,
	onClick,
	width,
	background,
	isLoading,
	opacityVal,
	margin,
}) {
	return (
		<Container
			opacity={opacityVal}
			width={width}
			border={border}
			background={background}
			margin={margin}
			onClick={() => {
				// e.preventDefault();
				onClick();
			}}
			// OnTouchStart={(e) => {
			// 	e.preventDefault();
			// 	onClick();
			// }}
		>
			{arrow === "left" && !isLoading && (
				<ArrowImg src={LeftArrow} alt="icon" />
			)}
			{arrow === "left" && !isLoading && (
				<ArrowImgPress src={LeftArrowPress} alt="icon" />
			)}
			{/* <TextDiv> */}
			{isLoading ? (
				<Lottie
					options={{
						loop: true,
						autoplay: true,
						animationData: animationData,
						rendererSettings: {
							preserveAspectRatio: "xMidYMid slice",
						},
					}}
					height={120}
					width={120}
					// eventListeners={eventListeners}
				/>
			) : (
				<Text color={color}>{text}</Text>
			)}
			{/* </TextDiv> */}
			{arrow === "right" && !isLoading && (
				<ArrowImg src={RightArrow} alt="icon" />
			)}
			{arrow === "right" && !isLoading && (
				<ArrowImgPress src={RightArrowPress} alt="icon" />
			)}
		</Container>
	);
}

export default Button;

const TextDiv = styled.div`
	margin: 0 10px;
`;
const Text = styled.span`
	color: ${(props) => (props.color ? props.color : "")};
	font-size: 25px;
	user-select: none;
	font-family: "Agatho-Medium";
	line-height: 10px;

	@media (max-width: 980px) {
		font-size: 24px;
	}
	@media (max-width: 640px) {
		font-size: 22px;
	}
	@media (max-width: 640px) {
		font-size: 20px;
	}
	@media (max-width: 460px) {
		font-size: 16px;
	}
`;
const ArrowImg = styled.img`
	/* margin-bottom: 5px; */
	display: block;
	margin: 0 5px;
	@media (max-width: 460px) {
		height: 30px;
		width: 30px;
		/* margin-bottom: 0px; */
	}
`;
const ArrowImgPress = styled.img`
	display: none;
	margin: 0 5px;
	/* margin-bottom: 5px; */
	@media (max-width: 460px) {
		height: 30px;
		width: 30px;
	}
`;
const Svg = styled.svg`
	:hover {
		fill: red;
	}
`;
const Container = styled.button`
	height: 60px;
	margin-right: 2%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	padding: 0 32px;
	border-radius: 36px;
	border: 2px solid;
	border-color: ${(props) =>
		props.border ? props.border : THEME_COLORS.secondary_5};
	cursor: pointer;
	user-select: none;
	background: ${(props) =>
		props.background ? props.background : THEME_COLORS.secondary_5};

	opacity: ${(props) => (props.opacity ? props.opacity : 1)};
	margin: ${(props) => (props.margin ? props.margin : "")};
	/* :hover {
		background: ${THEME_COLORS.secondary_2};
		border-color: ${THEME_COLORS.secondary_2};
	}
	:hover ${Text} {
		color: ${THEME_COLORS.primary_1};
	}
	:hover ${ArrowImgPress} {
		display: block;
	}
	:hover ${ArrowImg} {
		display: none;
	} */
	/* &:active {
		background: ${THEME_COLORS.secondary_2};
		border-color: ${THEME_COLORS.secondary_2};
	}
	&:active ${ArrowImg} {
		display: none;
	}
	&:active ${ArrowImgPress} {
		display: block;
	}
	&:active ${Text} {
		color: ${THEME_COLORS.primary_1};
	} */
	:last-child {
		margin-right: unset;
	}
	@media (max-width: 500px) {
		height: 60px;
		margin: 0 auto !important;
		padding-right: 20px;
		padding-left: 20px;
	}
	@media (max-width: 420px) {
		height: 40px;
	}
`;
