import React, {
	useContext,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import {
	useNavigate,
	useOutletContext,
	useParams,
	useLocation,
} from "react-router-dom";
import styled from "styled-components";
import { baseConfig } from "../../../axiosConfig";
import Lottie from "react-lottie";
import { THEME_COLORS } from "../../../configData";
import animationData from "./../../../assets/lotties/button-loader.json";
import { Context } from "../../../contexts/Store";

export default function Form1() {
	// Router constants
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [{ outletRef }] = useOutletContext();

	const { state, dispatch } = useContext(Context);

	const [selected, setSelected] = useState(state.profile.reference);
	const [references, setReferences] = useState([]);
	const [listLoading, setListLoading] = useState(true);

	useEffect(() => {
		const getReferences = () => {
			setListLoading(true);
			baseConfig
				.get("users/get-references/")
				.then((response) => {
					const { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						setReferences(data);
						setListLoading(false);
					} else {
						setListLoading(false);
					}
				})
				.catch((error) => {
					setListLoading(false);
				});
		};
		getReferences();
	}, []);

	const update = (value) => {
		setSelected(value);
		dispatch({
			type: "UPDATE_PROFILE",
			profile: {
				reference: value,
			},
		});
	};

	// Handle form submit
	const updateProfile = () => {
		// dispatch({
		// 	type: "UPDATE_PROFILE",
		// 	profile: {
		// 		reference: selected,
		// 	},
		// });
		// navigate(`/prakriti/preference/${id}/`);
		navigate({
			pathname: `/prakriti/preference/${id}/`,
			search: location.search,
		});
	};

	useImperativeHandle(outletRef, () => ({
		updateProfile,
	}));

	return (
		<div>
			<MainContent>
				<HeadingDiv>
					<Heading2>Enter Your Details</Heading2>
				</HeadingDiv>
				<Heading>How did you know about us?</Heading>
				{!listLoading ? (
					<MapContent style={{}}>
						{references.map((item) => (
							<FlexBox
								key={item.id}
								style={{
									background:
										selected === item.id ? "#fff" : null,
								}}
								onClick={() => {
									update(item.id);
								}}
							>
								<Title>{item.title}</Title>
							</FlexBox>
						))}
					</MapContent>
				) : (
					<LoaderDiv>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								animationData: animationData,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice",
								},
							}}
							height={120}
							width={120}
							// eventListeners={eventListeners}
						/>
					</LoaderDiv>
				)}
			</MainContent>
		</div>
	);
}

const MainContent = styled.div`
	height: 100%;
`;
const Heading = styled.h3`
	font-family: "DMMono-Regular";
	font-size: 20px;
	line-height: 26px;
	color: ${THEME_COLORS.text_2};
	@media (max-width: 420px) {
		font-size: 17px;
	}
`;
const MapContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 1.6rem;
	@media (max-width: 860px) {
		margin: 1.6rem 0 3rem 0;
	}
	@media (max-width: 420px) {
		margin: 15px 0 20px 0;
	}
`;
const FlexBox = styled.div`
	border: 2px solid #fadbb5;
	/* padding: 0.5rem 0.6rem; */
	border-radius: 10px;
	cursor: pointer;
	padding: 14px 20px 18px 20px;
	margin: 10px 0 0 0;
	width: 49%;
	@media (max-width: 420px) {
		margin: 8px 0 0 0;
		padding: 5px 10px 10px;
		width: 100%;
	}
`;

const Title = styled.h3`
	font-family: "Agatho-Medium";
	color: #6a6a6a;
	font-size: 20px;
	@media (max-width: 980px) {
		font-size: 18px;
	}
`;

const LoaderDiv = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;
const HeadingDiv = styled.div`
	user-select: none;
`;
const Heading2 = styled.h1`
	font-family: "Palatino-Regular";
	font-size: 26px;
	margin-bottom: 30px;
	color: #000;
	@media (max-width: 640px) {
		font-size: 23px;
	}
	@media (max-width: 375px) {
		margin-bottom: 10px;
	}
`;
