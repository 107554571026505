import React, { useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../configData";

export default function CheckBoxCustomDefault({
	item,
	onClick,
	grid_column,
	is_selected,
	margin,
}) {
	const [isSelected, setSelected] = useState(false);
	return (
		<Container
			margin={margin}
			selected={is_selected ? "selected" : ""}
			onClick={() => {
				onClick(item);
				setSelected((prev) => !prev);
			}}
			grid_column={grid_column}
		>
			<CheckBox selected={is_selected ? "selected" : ""}>
				{is_selected && (
					<IconContainerChecked>
						<Checked
							src={require("../../assets/icons/tick.svg").default}
						/>
					</IconContainerChecked>
				)}
			</CheckBox>
			{/* {item} */}
			<Text selected={is_selected ? "selected" : ""}>{item}</Text>
		</Container>
	);
}
const Container = styled.div`
	/* margin-bottom: 20px; */
	margin: ${(props) => (props.margin ? props.margin : 0)};
	display: flex;
	align-items: center;
	font-size: 26px;
	line-height: 28px;
	letter-spacing: 0.025em;
	grid-column: ${(props) => props.grid_column};
	color: ${({ selected }) =>
		selected === "selected" ? `${THEME_COLORS.primary_1}` : "#6a6a6a"};

	@media (max-width: 980px) {
		font-size: 22px;
	}
	@media (max-width: 640px) {
		font-size: 20px;
	}
	@media (max-width: 460px) {
		font-size: 18px;
		margin-bottom: 10px;
	}
`;
const IconContainerChecked = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const CheckBox = styled.div`
	display: block;
	height: 25px;
	width: 25px;
	border: 1px solid #b0b0b0;
	border-color: ${({ selected }) =>
		selected === "selected" ? `${THEME_COLORS.primary_1}` : "#b0b0b0"};
	cursor: pointer;
	margin-right: 15px;
	@media (max-width: 768px) {
		height: 28px;
		width: 28px;
	}
	@media (max-width: 640px) {
		height: 25px;
		width: 25px;
	}
	@media (max-width: 440px) {
		height: 20px;
		width: 20px;
	}
`;
const Text = styled.p`
	width: 90%;
	color: ${({ selected }) =>
		selected === "selected" ? `${THEME_COLORS.primary_1}` : "#6a6a6a"};
	font-family: "Agatho-Medium";
	font-size: 22px;
	line-height: 25px;
	@media (max-width: 640px) {
		font-size: 24px;
	}
	@media (max-width: 460px) {
		font-size: 18px;
	}
`;
const Checked = styled.img`
	display: block;
	width: 20px;
	height: 30px;
	@media (max-width: 640px) {
		height: 25px;
		width: 15px;
	}
	@media (max-width: 440px) {
		height: 20px;
	}
`;
