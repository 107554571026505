import React, { useEffect, useState, useRef, useContext } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { THEME_COLORS } from "./../../../configData";
import styled, { keyframes } from "styled-components";
import Button from "../../includes/Button";
import BodyType from "../../includes/BodyType";

//images
import FeedbackBg1 from "../../../assets/images/feedback-1.png";
import FeedbackBg2 from "../../../assets/images/feedback-2.png";
import FeedbackBg3 from "../../../assets/images/feedback-3.png";
import FeedbackBg4 from "../../../assets/images/feedback-4.png";
import { Context } from "../../../contexts/Store";
import { display } from "@mui/system";

export default function MainPage({ image_type, router }) {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);
	const [showBg, setShowBg] = useState(true);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	};
	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const [bgImage, setBgImage] = useState("prakriti");
	const [pageStatus, setPageStatus] = useState("");
	const [feedbackImage, setFeedbackImage] = useState(FeedbackBg1);
	const location = useLocation();
	const navigate = useNavigate();
	const [title, setTitle] = useState("Enter your details");
	const [isLoading, setLoading] = useState(false);

	const registerRef = useRef();

	const [ratingData, setRatingData] = useState({});
	const [resultObject, setResultObject] = useState({});
	const { state } = useContext(Context);

	const previousButton = () => {
		navigate(-1);
	};

	const nextButton = () => {
		if (pageStatus === "register") {
			registerRef.current.registerAccount();
		} else if (pageStatus === "personal") {
			registerRef.current.updateProfile();
		} else if (pageStatus === "know-us") {
			registerRef.current.updateProfile();
		} else if (pageStatus === "preference") {
			registerRef.current.updateProfile();
		} else if (pageStatus === "body-part") {
			registerRef.current.updateProfile();
		} else if (pageStatus === "conditions") {
			registerRef.current.submitProfile();
		} else if (pageStatus === "questions") {
			navigate("/result/");
		}
	};

	const feedBackNext = () => {
		if (pageStatus === "reception") {
			navigate("/feedback/therapist/");
		} else if (pageStatus === "therapist") {
			navigate("/feedback/amenities/");
		} else if (pageStatus === "amenities") {
			navigate("/feedback/submit/", {
				state: {
					feedbackData: ratingData,
				},
			});
		}
	};

	useEffect(() => {
		if (location?.pathname.search("register") !== -1) {
			setPageStatus("register");
			setBgImage("prakriti");
			setTitle("Enter your details");
		} else if (location?.pathname.search("personal") !== -1) {
			setPageStatus("personal");
			setBgImage("prakriti");
			setTitle("Enter your details");
		} else if (location?.pathname.search("know-us") !== -1) {
			setPageStatus("know-us");
			setBgImage("prakriti");
			setTitle("Enter your details");
		} else if (location?.pathname.search("preference") !== -1) {
			setPageStatus("preference");
			setBgImage("preference");
			setTitle("Pressure Preferance");
		} else if (location?.pathname.search("body-part") !== -1) {
			setPageStatus("body-part");
			setBgImage("body-part");
			setTitle("Choose Body Parts");
		} else if (location?.pathname.search("conditions") !== -1) {
			setPageStatus("conditions");
			setBgImage("preference");
			setTitle("Specify below details");
		} else if (location?.pathname.search("questions") !== -1) {
			setPageStatus("questions");
			setBgImage("prakriti");
			setTitle("Know your Prakriti");
		} else if (location?.pathname.search("verify") !== -1) {
			setPageStatus("verify");
			setBgImage("feedback");
			setFeedbackImage(FeedbackBg1);
			setShowBg(true);
			setTitle("Enter your Feedback");
		} else if (location?.pathname.search("reception") !== -1) {
			setPageStatus("reception");
			setBgImage("feedback");
			setFeedbackImage(FeedbackBg2);
			setShowBg(false);
			setTitle("How was the Reception?");
		} else if (location?.pathname.search("therapist") !== -1) {
			setPageStatus("therapist");
			setBgImage("feedback");
			setFeedbackImage(FeedbackBg3);
			setShowBg(false);
			setTitle("How was your Therapist?");
		} else if (location?.pathname.search("amenities") !== -1) {
			setPageStatus("amenities");
			setBgImage("feedback");
			setFeedbackImage(FeedbackBg4);
			setShowBg(false);
			setTitle("How was the amenities?");
		} else if (location?.pathname.search("submit") !== -1) {
			setPageStatus("submit");
			setBgImage("feedback");
			setFeedbackImage(FeedbackBg1);
			setShowBg(true);
			setTitle("Enter your Feedback");
		} else {
			setPageStatus("");
			setBgImage("prakriti");
			setFeedbackImage(FeedbackBg1);
		}
	}, [location]);

	let outletObject = {
		pageStatus: pageStatus,
		outletRef: registerRef,
		ratingData: ratingData,
		setRatingData: setRatingData,
		resultObject: resultObject,
		setResultObject: setResultObject,
		setLoading: setLoading,
	};

	return (
		<MainContainer height={height}>
			<Container>
				<BottomContainer>
					<BottomLeftContainer>
						<MainBox>
							<TopContainer>
								<LogoContainer>
									<Link to={"/"}>
										<Logo
											src={
												require("./../../../assets/icons/aira-logo.svg")
													.default
											}
											alt="logo"
										/>
										<LogoResponsive
											src={
												require("./../../../assets/icons/flower.svg")
													.default
											}
											alt="logo"
										/>
									</Link>
								</LogoContainer>
								{/* <TopRight>
									{router === "feedback" ? (
										<ProgressBar>
											<ProgressItem active="active" />
											<ProgressItem
												active={
													(pageStatus ===
														"reception" ||
														pageStatus ===
															"therapist" ||
														pageStatus ===
															"amenities" ||
														pageStatus ===
															"submit") &&
													"active"
												}
											/>
											<ProgressItem
												active={
													(pageStatus ===
														"therapist" ||
														pageStatus ===
															"amenities" ||
														pageStatus ===
															"submit") &&
													"active"
												}
											/>
											<ProgressItem
												active={
													(pageStatus ===
														"amenities" ||
														pageStatus ===
															"submit") &&
													"active"
												}
											/>
											<ProgressItem
												active={
													pageStatus === "submit" &&
													"active"
												}
											/>
										</ProgressBar>
									) : null}
								</TopRight> */}
							</TopContainer>
						</MainBox>
						{bgImage === "prakriti" ? (
							<ImagesDiv>
								<BgImage
									src={require("./../../../assets/images/form-1-bg.png")}
									alt="image"
								/>
								<BadgeImage
									src={
										require("./../../../assets/icons/round-badge.svg")
											.default
									}
									alt="icon"
								/>
							</ImagesDiv>
						) : bgImage === "body-part" ? (
							<BodyType />
						) : bgImage === "preference" ? (
							<ImagesDiv>
								<BgImage
									src={require("./../../../assets/images/bg-2.png")}
									alt="image"
								/>
								<BadgeImageSecondDiv
									src={
										require("./../../../assets/icons/round-badge.svg")
											.default
									}
									alt="icon"
								/>
							</ImagesDiv>
						) : bgImage === "feedback" ? (
							<ImagesDiv showBg={showBg}>
								{/* {showBg && 
									<BgImage src={feedbackImage} alt="image" />
								} */}
								<BgImage src={feedbackImage} alt="image" />

								<BadgeImageFeedback
									src={
										require("./../../../assets/icons/round-badge.svg")
											.default
									}
									alt="icon"
								/>
							</ImagesDiv>
						) : null}
						{router === "feedback" &&
							pageStatus !== "verify" &&
							pageStatus !== "submit" && (
								<TopButtons>
									<FeedbackButtons>
										<PreviousButton
											onClick={() => {
												navigate(-1);
											}}
										>
											Previous
										</PreviousButton>
										<NextButton
											onClick={() => {
												feedBackNext();
											}}
										>
											Next
										</NextButton>
									</FeedbackButtons>
								</TopButtons>
							)}
					</BottomLeftContainer>
					<BottomRightContainer>
						<TopRight>
							{router === "feedback" ? (
								<ProgressBar>
									<ProgressItem active="active" />
									<ProgressItem
										active={
											(pageStatus === "reception" ||
												pageStatus === "therapist" ||
												pageStatus === "amenities" ||
												pageStatus === "submit") &&
											"active"
										}
									/>
									<ProgressItem
										active={
											(pageStatus === "therapist" ||
												pageStatus === "amenities" ||
												pageStatus === "submit") &&
											"active"
										}
									/>
									<ProgressItem
										active={
											(pageStatus === "amenities" ||
												pageStatus === "submit") &&
											"active"
										}
									/>
									<ProgressItem
										active={
											pageStatus === "submit" && "active"
										}
									/>
								</ProgressBar>
							) : null}
						</TopRight>
						<Outlet context={[outletObject]} />
						{router !== "feedback" && pageStatus !== "questions" ? (
							<FooterButton>
								{pageStatus !== "register" && (
									<Button
										color={THEME_COLORS.secondary_2}
										border={THEME_COLORS.secondary_5}
										// background={THEME_COLORS.background_1}
										text="Previous"
										arrow="left"
										width="48%"
										onClick={previousButton}
									/>
								)}
								{pageStatus && (
									<Button
										color={THEME_COLORS.secondary_2}
										border={THEME_COLORS.secondary_5}
										// background={THEME_COLORS.secondary_2}
										text={
											pageStatus === "conditions"
												? "Submit"
												: "Next"
										}
										arrow={
											pageStatus === "conditions"
												? null
												: "right"
										}
										width={
											pageStatus === "register"
												? "100%"
												: "48%"
										}
										onClick={nextButton}
										isLoading={isLoading}
									/>
								)}
							</FooterButton>
						) : (
							router === "feedback" &&
							pageStatus !== "verify" &&
							pageStatus !== "submit" && (
								<ResposiveButton>
									<FeedbackButtons>
										<PreviousButton
											onClick={() => {
												navigate(-1);
											}}
										>
											Previous
										</PreviousButton>
										<NextButton
											onClick={() => {
												feedBackNext();
											}}
										>
											Next
										</NextButton>
									</FeedbackButtons>
								</ResposiveButton>
							)
						)}
					</BottomRightContainer>
				</BottomContainer>
			</Container>
		</MainContainer>
	);
}

const MainContainer = styled.div`
	height: ${(props) => (props.height ? `${props.height}px` : null)};
	background-color: ${THEME_COLORS.background_1};
	padding: 60px;
	@media (max-height: 1024px) {
		/* padding: 40px 60px; */
	}
	@media (max-width: 768px) {
		padding: 50px;
		display: flex;
	}
	@media (max-width: 375px) {
		padding: 30px;
		height: unset;
	}
`;
const Container = styled.div`
	width: 100%;

	@media (max-width: 768px) {
		display: flex;
	}
`;
const TopContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	/* height: 100px; */
	@media (max-width: 768px) {
		display: block;
	}
	@media (max-width: 460px) {
		/* height: 100px; */
	}
`;
const TopRight = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
	/* height: 25px; */
	@media (max-width: 980px) {
		/* width: 55%; */
	}
	@media (max-width: 768px) {
		width: 100%;
	}
	@media (max-width: 460px) {
		margin-bottom: 30px;
	}
	@media (max-width: 375px) {
		margin-bottom: 15px;
	}
`;

const BottomContainer = styled.div`
	display: flex;
	/* align-items: center; */
	justify-content: space-between;
	/* padding-top: 80px; */

	@media (max-width: 1080px) {
		/* padding-top: 80px; */
		/* padding-bottom: 80px; */
	}
	/* @media screen and (max-height: ){
		
	} */
	@media screen and (max-width: 860px) {
	}
	@media (max-width: 768px) {
		flex-direction: column;
		width: 100%;
	}
	@media (max-width: 640px) {
		/* padding-top: 30px; */
	}
	@media (max-width: 460px) {
		/* padding-top: 30px; */
		/* height: calc(100vh - 150px); */
		/* background: red; */
	}
	@media (max-width: 420px) {
		/* padding-top: 10px; */
	}
`;
const BottomLeftContainer = styled.div`
	width: 40%;
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	/* display: flex; */
	/* justify-content: center; */
	@media (max-width: 768px) {
		width: 100%;
		margin-bottom: 20px;
	}
	@media (max-width: 460px) {
		width: 100%;
		margin-bottom: 10px;
	}
`;
const BottomRightContainer = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 85vh;
	@media (max-width: 980px) {
		width: 55%;
	}
	@media (max-width: 768px) {
		height: 100%;
		width: 100%;
	}
	@media (max-width: 460px) {
		/* height: calc(100vh - 180px); */
		/* width: 100%; */
	}
`;
const LogoContainer = styled.div`
	margin-bottom: 10px;
	@media (max-width: 768px) {
		/* margin-bottom: 40px; */
	}
`;

const MainBox = styled.div``;
const Logo = styled.img`
	width: 200px;
	@media (max-width: 460px) {
		width: 150px;
	}
	@media (max-width: 370px) {
		width: 120px;
	}
`;
const LogoResponsive = styled.img`
	display: none;
	/* @media (max-width: 768px) {
		display: block;
		width: 80px;
		margin: 0 auto;
	} */
	/* @media (max-width: 460px) {
		display: block;
		width: 85px;
		margin: 0 auto;
	}
	@media (max-width: 375px) {
		width: 70px;
	} */
`;
const ImagesDiv = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 90%;
	position: relative;

	@media (max-width: 980px) {
		width: 100%;
	}
	@media (max-width: 768px) {
		display: ${(props) => (props.showBg === true ? "flex" : "none")};
		width: 50%;
		margin: 0 auto;
	}
`;
const BgImage = styled.img`
	display: inline-block;
	width: 100%;
	height: min-intrinsic;
	/* height: fit-content; */
`;

const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

const BadgeImage = styled.img`
	position: absolute;
	left: -3%;
	width: 20%;
	animation: ${rotate} 10s linear infinite;
	bottom: -3%;
`;
const BadgeImageSecondDiv = styled.img`
	position: absolute;
	left: 14%;
	width: 23%;
	animation: ${rotate} 10s linear infinite;
	top: 4%;
`;
const BadgeImageFeedback = styled.img`
	position: absolute;
	right: 3%;
	width: 25%;
	animation: ${rotate} 10s linear infinite;
	bottom: 3%;
`;
const ResposiveButton = styled.div`
	display: none;
	@media (max-width: 1280px) {
		display: flex;
		margin-bottom: 30px;
	}
	@media (max-width: 460px) {
		display: flex;
		margin-bottom: 0px;
	}
`;
const TopButtons = styled.div`
	@media screen and (max-width: 1280px) {
		display: none;
	}
`;
const FeedbackButtons = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0 40px;
	font-size: 23px;
	text-decoration: underline;
	text-decoration-color: ${THEME_COLORS.secondary_1};
	margin-top: 30px;
	width: 100%;

	@media (max-width: 1280px) {
		font-size: 20px;
	}
	@media (max-width: 768px) {
		padding: 0px;
	}
	@media (max-width: 460px) {
		margin-bottom: 20px;
	}
`;
const PreviousButton = styled.span`
	cursor: pointer;
	color: ${THEME_COLORS.secondary_1};
	user-select: none;
	@media (max-width: 768px) {
		font-size: 18px;
	}
`;
const NextButton = styled.span`
	cursor: pointer;
	user-select: none;
	color: ${THEME_COLORS.secondary_1};
	@media (max-width: 768px) {
		font-size: 18px;
	}
`;
const RightContainer = styled.div`
	width: 55%;
	height: 50vh;
	/* background-color: blue; */
`;
const ProgressBar = styled.div`
	width: 100%;
	margin-bottom: 15px;
`;
const ProgressItem = styled.span`
	width: 18%;
	height: 4px;
	background-color: ${(props) =>
		props.active === "active"
			? THEME_COLORS.secondary_1
			: THEME_COLORS.secondary_2};
	display: inline-block;
	margin-right: 2.5%;
	:last-child {
		margin-right: unset;
	}
`;

const FooterButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 25px;
	@media (max-width: 420px) {
		margin-top: 0;
	}
`;
