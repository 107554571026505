import React, { useContext, useEffect, useImperativeHandle } from "react";
import {
	useNavigate,
	useOutletContext,
	useParams,
	useLocation,
} from "react-router-dom";
import styled from "styled-components";
import { baseConfig } from "../../../axiosConfig";
import { Context } from "../../../contexts/Store";
import BodyType from "../../includes/BodyType";

export default function Form4() {
	// Router constants
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [{ outletRef }] = useOutletContext();
	const { state, dispatch } = useContext(Context);

	useEffect(() => {
		const getBodyParts = () => {
			baseConfig
				.get("users/get-body-parts/")
				.then((response) => {
					const { StatusCode, data } = response.data;
					if (StatusCode === 6000) {
						// Merging arrays
						let bodyParts =
							state.profile.body_parts.length > 0
								? [
										...state.bodyParts.map((item) => {
											if (
												state.profile.body_parts.includes(
													item.id
												)
											) {
												item.is_selected = true;
											}
											return item;
										}),
								  ]
								: [...state.bodyParts];
						bodyParts = bodyParts.map((bodyPart) => {
							const bodyItem = bodyPart;
							data.forEach((item) => {
								if (bodyPart.type === item.title) {
									bodyItem.id = item.id;
									return null;
								}
							});
							return bodyItem;
						});
						dispatch({
							type: "SET_BODY_PARTS",
							bodyParts: bodyParts,
						});
					} else {
					}
				})
				.catch((error) => {});
		};
		getBodyParts();
	}, []);

	const handleOptionClick = (bodyPart) => {
		dispatch({
			type: "UPDATE_BODY_PARTS",
			bodyPart: {
				...bodyPart,
				is_selected: bodyPart.is_selected ? false : true,
			},
		});
	};

	// Handle form submit
	const updateProfile = () => {
		const body_parts = state.bodyParts
			.filter((bodyPart) => bodyPart.is_selected)
			.map((bodyPart) => bodyPart.id);
		dispatch({
			type: "UPDATE_PROFILE",
			profile: {
				body_parts: body_parts,
			},
		});
		// navigate(`/prakriti/conditions/${id}/`);
		navigate({
			pathname: `/prakriti/conditions/${id}/`,
			search: location.search,
		});
	};

	useImperativeHandle(outletRef, () => ({
		updateProfile,
	}));

	return (
		<Container>
			<LeftContainer>
				<BodyType />
			</LeftContainer>
			<RightContainer>
				<HeadingDiv>
					<Heading>Choose Body Parts</Heading>
				</HeadingDiv>
				{state.bodyParts.map((bodyPart) => (
					<Option
						selected={bodyPart.is_selected ? "selected" : ""}
						key={bodyPart.type}
						onClick={() => handleOptionClick(bodyPart)}
					>
						{bodyPart.is_selected && (
							<OptionIcon
								src={
									require("../../../assets/icons/tick.svg")
										.default
								}
							/>
						)}
						<OptionText
							selected={bodyPart.is_selected ? "selected" : ""}
						>
							{bodyPart.type}
						</OptionText>
					</Option>
				))}
			</RightContainer>
		</Container>
	);
}
const Container = styled.div`
	@media (max-width: 768px) {
		/* margin-bottom: 50px; */
		display: flex;
		justify-content: space-around;
		overflow-y: scroll;
	}
`;
const LeftContainer = styled.div`
	display: none;
	@media (max-width: 768px) {
		/* display: flex; */
	}
`;
const RightContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	@media (max-width: 768px) {
		/* flex-direction: column;
		flex-wrap: nowrap; */
	}
`;

const Option = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 32px;
	width: 46%;
	border: 2px solid #fadbb5;
	border-radius: 16px;
	background: ${(props) =>
		props.selected === "selected" ? "#fadbb5" : "#fff"};
	margin-bottom: 15px;
	cursor: pointer;
	@media (max-width: 768px) {
		padding: 10px 25px;
		width: 100%;
		margin-bottom: 7px;
	}
`;
const OptionIcon = styled.img`
	display: block;
	width: 25px;
	margin-right: 15px;
	@media (max-width: 768px) {
		width: 15px;
		margin-right: 10px;
	}
`;
const OptionText = styled.span`
	font-family: "Agatho-Medium";
	font-size: 22px;
	color: ${(props) =>
		props.selected === "selected" ? "#6E421B" : "#6a6a6a"};
	@media (max-width: 768px) {
		font-size: 24px;
	}
	@media (max-width: 768px) {
		font-size: 20px;
	}
`;
const HeadingDiv = styled.div`
	user-select: none;
	width: 100%;
`;
const Heading = styled.h1`
	font-family: "Palatino-Regular";
	font-size: 26px;
	margin-bottom: 30px;
	color: #000;
	@media (max-width: 640px) {
		font-size: 23px;
	}
	@media (max-width: 375px) {
		margin-bottom: 10px;
	}
`;
