import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Pitta from "../../../assets/images/pitta.svg";
import Kapha from "../../../assets/images/kapha.svg";
import Vata from "../../../assets/images/vata.svg";
import Button from "../../includes/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { THEME_COLORS } from "../../../configData";
import { baseConfig } from "../../../axiosConfig";

export default function Result() {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	const updateDimensions = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	};
	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const navigate = useNavigate();
	const [isLoading, setLoading] = useState(true);
	const [result, setResult] = useState({});
	const { id } = useParams();
	const [types, setTypes] = useState([
		{
			name: "Pitta",
			percentage: "30%",
			image: Pitta,
		},
		{
			name: "Kapha",
			percentage: "45%",
			image: Kapha,
		},
		{
			name: "Vata",
			percentage: "20%",
			image: Vata,
		},
	]);

	//get results
	const getResults = () => {
		setLoading(true);
		baseConfig
			.get(`users/get-body-type/${id}`, {})

			.then((response) => {
				const { StatusCode, data } = response.data;
				if (StatusCode === 6000) {
					setResult(data);

					setTimeout(() => {
						setLoading(false);
					}, 200);
				} else {
					setTimeout(() => {
						setLoading(false);
					}, 200);
				}
			})
			.catch((error) => {
				setTimeout(() => {
					setLoading(false);
				}, 200);
			});
	};

	useEffect(() => {
		getResults();
	}, []);

	const feedBackNext = () => {
		navigate("/feedback/verify/");
	};

	const [matches, setMatches] = useState(
		window.matchMedia("(min-width: 685px)").matches
	);

	useEffect(() => {
		window
			.matchMedia("(min-width: 685px)")
			.addEventListener("change", (e) => setMatches(e.matches));
	}, []);

	const onBackButtonEvent = (e) => {
		e.preventDefault();
		navigate("/");
	};

	useEffect(() => {
		window.history.pushState(null, null, window.location.pathname);
		window.addEventListener("popstate", onBackButtonEvent);
		return () => {
			window.removeEventListener("popstate", onBackButtonEvent);
		};
	}, []);

	return (
		<Container height={height}>
			<BgImageLeft
				src={require("../../../assets/images/bg-left.svg").default}
			/>
			<BgImageRight
				src={require("../../../assets/images/bg-right.svg").default}
			/>
			{!isLoading && (
				<Body>
					<InnerContainer>
						<LogoContainer>
							<Link to={"/"}>
								<Logo
									src={
										require("../../../assets/icons/aira-logo.svg")
											.default
									}
								/>
							</Link>
						</LogoContainer>
						<TypeContainer>
							{/* {types.map((data) => ( */}
							<Type>
								<ImageContainer>
									<Logo src={Pitta} />
								</ImageContainer>
								<TypeName>
									Vata {Math.round(result.vata_percent)}%
								</TypeName>
							</Type>
							<Type>
								<ImageContainer>
									<Logo src={Kapha} />
								</ImageContainer>
								<TypeName>
									Pitta {Math.round(result.pitta_percent)}%
								</TypeName>
							</Type>
							<Type>
								<ImageContainer>
									<Logo src={Vata} />
								</ImageContainer>
								<TypeName>
									Kapha {Math.round(result.kapha_percent)}%
								</TypeName>
							</Type>
							{/* ))} */}
						</TypeContainer>
						<DescriptionContainer>
							<Title>{result.body_type}</Title>
							<Description>{result.description}</Description>
							<SubText>
								You will recieve an email which explain your
								prakriti
							</SubText>
						</DescriptionContainer>
					</InnerContainer>
					<BottomContainer>
						<BackButtonDiv to={"/"}>
							<BackButton>Back to home</BackButton>
						</BackButtonDiv>
						<Button
							color={THEME_COLORS.secondary_2}
							border={THEME_COLORS.secondary_5}
							// background={THEME_COLORS.secondary_2}
							text="Add Feedback"
							arrow="right"
							width={!matches ? "100%" : "30%"}
							onClick={feedBackNext}
						/>
					</BottomContainer>
				</Body>
			)}
		</Container>
	);
}
const Container = styled.div`
	padding: 80px;
	background: ${THEME_COLORS.background_1};
	position: relative;
	height: ${(props) => (props.height ? `${props.height}px` : null)};
	@media (max-width: 500px) {
		padding: 30px;
	}
	@media (max-width: 370px) {
		height: unset;
	}
`;
const BgImageLeft = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	@media (max-width: 640px) {
		width: 50px;
	}
`;
const BgImageRight = styled.img`
	position: absolute;
	top: 0;
	right: 0;
	@media (max-width: 640px) {
		width: 50px;
	}
`;
const Body = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;
const InnerContainer = styled.div`
	width: 90%;
	margin: 0 auto;
	@media (max-width: 980px) {
		width: 95%;
	}
	@media (max-width: 768px) {
		width: 100%;
	}
`;
const LogoContainer = styled.div`
	width: 200px;
	margin: 0 auto;
	@media (max-width: 500px) {
		width: 150px;
	}
`;
const Logo = styled.img`
	display: block;
	width: 90%;
	margin: 0 auto;
`;
const Type = styled.div`
	@media (max-width: 820px) {
		margin-bottom: 50px;
	}
	@media (max-width: 600px) {
		margin-bottom: 10px;
	}
`;
const TypeContainer = styled.div`
	width: 80%;
	display: flex;
	justify-content: space-between;
	margin: 25px auto;
	@media (max-width: 980px) {
		width: 90%;
	}
	@media (max-width: 768px) {
		width: 100%;
	}
	@media (max-width: 600px) {
		display: block;
	}
`;
const ImageContainer = styled.div`
	@media (max-width: 820px) {
		width: 50%;
		margin: 0 auto;
	}
	@media (max-width: 640px) {
		width: 25%;
		display: none;
	}
`;
const TypeName = styled.p`
	border: 2px solid #fadbb5;
	padding: 10px 32px;
	color: #000;
	text-align: center;
	font-size: 22px;
	line-height: 28px;
	border-radius: 64px;
	margin-top: 10px;
	text-align: center;
	@media (max-width: 980px) {
		font-size: 22px;
	}
	@media (max-width: 820px) {
		width: max-content;
		margin: 0 auto;
	}
	@media (max-width: 640px) {
		padding: 3px 20px;
		font-size: 17px;
	}
	@media (max-width: 420px) {
		width: -moz-max-content;
		width: -webkit-max-content;
		width: -o-max-content;
		width: max-content;
	}
`;
const DescriptionContainer = styled.div`
	padding: 25px 30px;
	background: #fff;
	border-radius: 20px;
	@media (max-width: 420px) {
		padding: 20px;
	}
`;
const Title = styled.h2`
	font-size: 30px;
	line-height: 35px;
	color: #000000;
	text-align: center;
	margin-bottom: 7px;
	@media (max-width: 980px) {
		font-size: 26px;
	}
`;
const Description = styled.p`
	font-size: 22px;
	line-height: 28px;
	color: #6a6a6a;
	text-align: center;
	margin-bottom: 5px;
	@media (max-width: 980px) {
		font-size: 24px;
	}
	@media (max-width: 768px) {
		font-size: 22px;
		margin-bottom: 15px;
	}
	@media (max-width: 460px) {
		font-size: 16px;
		margin-bottom: 0;
	}
`;
const SubText = styled(Description)`
	color: #b0b0b0;
	margin-bottom: 0;
`;
const BackButtonDiv = styled(Link)`
	cursor: pointer;
	@media (max-width: 680px) {
		margin: 30px 0;
	}
`;
const BackButton = styled.h4`
	color: ${THEME_COLORS.secondary_1};
	font-family: "Agatho-Medium";
	font-size: 28px;
	text-decoration: underline;
	@media (max-width: 768px) {
		font-size: 24px;
	}
	@media (max-width: 640px) {
		font-size: 22px;
	}
	@media (max-width: 460px) {
		font-size: 20px;
	}
`;
const BottomContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 30px;
	@media (max-width: 680px) {
		flex-direction: column-reverse;
		margin-top: 10px;
	}
`;
