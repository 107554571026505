import React from "react";
import styled from "styled-components";
import { THEME_COLORS } from "./../../../configData";
import { Link } from "react-router-dom";

export default function PrakrithiStart() {
	return (
		<Container>
			<SelectionDiv>
				<FeedBackContainer>
					<FeedBackDiv to={"/prakriti/register/"}>
						<FeedBackIconDiv>
							<FeedBackIcon
								src={
									require("./../../../assets/icons/profile.svg")
										.default
								}
							/>
						</FeedBackIconDiv>
						<FeedBackTextDiv>
							<FeedBackText>Know your Prakriti</FeedBackText>
						</FeedBackTextDiv>
					</FeedBackDiv>
					<FeedBackDiv to={"/feedback/verify/"}>
						<FeedBackIconDiv>
							<FeedBackIcon
								src={
									require("./../../../assets/icons/feedback.svg")
										.default
								}
							/>
						</FeedBackIconDiv>
						<FeedBackTextDiv>
							<FeedBackText>Add your Feedback</FeedBackText>
						</FeedBackTextDiv>
					</FeedBackDiv>
				</FeedBackContainer>
				<BackButtonDiv to={"/"}>
					<BackButton>Back to home</BackButton>
				</BackButtonDiv>
			</SelectionDiv>
		</Container>
	);
}

const Container = styled.div`
	@media (max-width: 460px) {
		width: 100%;
	}
`;

const SelectionDiv = styled.div``;
const FeedBackContainer = styled.div`
	display: flex;
	grid-gap: 30px;
	@media (max-width: 560px) {
		grid-gap: 15px;
	}
	@media (max-width: 460px) {
		flex-direction: column;
	}
`;
const FeedBackDiv = styled(Link)`
	border-radius: 36px;
	border: 2px solid;
	border-color: ${THEME_COLORS.secondary_2};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px 20px;
	margin-bottom: 32px;
	cursor: pointer;
	@media (max-width: 1024px) {
		padding: 20px;
	}
	@media (max-width: 460px) {
		flex-direction: row;
		align-items: center;
		margin-bottom: 0px;
		padding: 10px 20px;
		border-radius: 14px;
	}
`;
const FeedBackIconDiv = styled.div`
	margin-bottom: 15px;
	@media (max-width: 460px) {
		margin-bottom: 0px;
	}
`;
const FeedBackIcon = styled.img`
	display: inline-block;
	@media (max-width: 460px) {
		width: 24px;
		height: 24px;
	}
	@media (max-width: 300px) {
		width: 20px;
		height: 20px;
	}
`;
const FeedBackTextDiv = styled.div`
	width: 80%;
	@media (max-width: 460px) {
		width: unset;
		margin-left: 10px;
		margin-bottom: 5px;
	}
`;
const FeedBackText = styled.h3`
	font-family: "Agatho-Medium";
	font-size: 25px;
	color: ${THEME_COLORS.primary_1};
	text-align: center;
	@media (max-width: 640px) {
		font-size: 22px;
	}
	@media (max-width: 460px) {
		font-size: 16px;
	}
`;
const BackButtonDiv = styled(Link)`
	cursor: pointer;
	@media (max-width: 460px) {
		display: flex;
		align-items: center;
		justify-content: center;
		/* width: 100%; */
		margin-top: 32px;
		width: unset;
	}
	@media (max-width: 320px) {
		margin-top: 20px;
	}
`;
const BackButton = styled.h4`
	color: ${THEME_COLORS.secondary_1};
	font-family: "Agatho-Medium";
	font-size: 28px;
	text-decoration: underline;
	@media (max-width: 460px) {
		font-size: 18px;
	}
`;
